import React from "react";
import Container from "react-bootstrap/Container";
import logo from "./../images/ME logo white 1.png";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FaInstagram, FaLinkedin, FaTwitter, FaMedium, FaFacebook, } from 'react-icons/fa';
import { HiLocationMarker } from 'react-icons/hi'

import robotc from "../TERMS.pdf";


const Footer = () => {

  return (

    <div className="footer-bg-banner" id="contact-us">
      <div className="footer-flex">
        <Container>
          <div className="pt-5 text-s-c">
            <img src={logo} className="logo" alt="" />
          </div>
          <p className="text-white">
            The Equation of My Career
          </p>
          <Row>
            <Col className="text-white py-2">
              <div className="d-flex align-items-center">
                <HiLocationMarker className="pr-1 font-size-3" /><b>Location:</b>

              </div>
              GUSEC West Wing,

              <br />
              Gujarat University, Gujarat, 380009
            </Col>
          </Row>
          <Row>
            <Col className="text-white py-2">
              <div className="d-flex align-items-center">
                <HiLocationMarker className="pr-1 font-size-3" /><b>Registered Location:</b>
              </div>
              E-504 Karnavati Apartment -2,
              <br />
              Narolgam, Ahmedabad, 382405
            </Col>
          </Row>
        </Container>
        <Container>
          <div className="py-4">
            <Row>
              <Col md={3}>
                <h4 className="text-white">Resources</h4>
                <ul className="list-unstyled text-white">
                  <li>
                    <Link className="foota" to="/whyus">About Us</Link>
                  </li>
                  <li>
                    <a className="foota" href="https://forms.gle/SCLDDempeYn213w18" target="_blank">Enroll Now</a>
                  </li>
                  <li>
                    <a className="foota" href="https://forms.gle/mMMJsxZCYVZ5S6dw5" target="_blank">Become a Mentor</a>
                  </li>
                  <li>
                    <a className="foota" href="https://t.me/+St93ZI0MmO1lNDg1" target="_blank">Join our community</a>
                  </li>
                  <li>
                    <a className="foota" href="https://medium.com/@myequation/beyond-the-horizons-where-we-equate-your-career-85e617c89308" target="_blank">Blog</a>
                  </li>
                  <li>
                    <Link className="foota" to={"/whyus?scrollToFAQ=true"}>FAQ</Link>
                  </li>

                </ul>
              </Col>

              <Col md={3}>
                <h4 className="text-white">Policies</h4>
                <ul className="list-unstyled text-white">
                  <li>
                    <a className="foota" href="https://drive.google.com/file/d/1B_J6zIL9F00V1EO-1_vyLnFXPQES-tw4/view?usp=sharing" target="_blank">Privacy Policy</a>
                  </li>
                  <li>
                    <a className="foota" href="https://drive.google.com/file/d/19TERLWZI4gqtQsLqEKYkBV1oxWUMUSIb/view?usp=sharing" target="_blank">Terms & Conditions</a>
                  </li>
                  <li>
                    <a className="foota" href="https://drive.google.com/file/d/1zMzgY82ia-2MrN-IZ-MioE8QGEaFXCfj/view?usp=sharing" target="_blank">Refund Policy</a>
                  </li>

                </ul>
              </Col>

              <Col md={3} className="text-white">
                <h4>Follow Us</h4>
                <ul className="list-unstyled">
                  <li>
                    <a className="foota" href="https://www.instagram.com/myequation.in/" target="_blank">
                      <FaInstagram /> Instagram
                    </a>
                  </li>
                  <li>
                    <a className="foota" href="https://www.linkedin.com/company/my-equation/" target="_blank">
                      <FaLinkedin /> Linkedin
                    </a>
                  </li>
                  <li>
                    <a className="foota" href="https://twitter.com/MyEquation_in" target="_blank">
                      <FaTwitter /> Twitter
                    </a>
                  </li>
                  <li>
                    <a className="foota" href="https://medium.com/@myequation" target="_blank">
                      <FaMedium /> Medium
                    </a>
                  </li>
                  <li>
                    <a className="foota" href="https://www.facebook.com/myequation.in" target="_blank">
                      <FaFacebook /> Facebook
                    </a>
                  </li>
                </ul>
              </Col>
              <Col md={3} className="text-white footer-contact">
                <h4>Contact Us</h4>
                <p><a className="foota" href="mailto:info@techanalogy.org">info@techanalogy.org</a></p>
                <p>Gunesh Patil: <a className="foota" href="tel:+918806806479">8806806479</a></p>
                <p><a className="foota" href="mailto:smohead@techanalogy.org">smohead@techanalogy.org</a></p>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
           <p

  className="footer-copyright-text"
  style={{
    color: "#fff",
    textAlign: "center",
  }}
>
  By accessing and using this website, you agree to abide by our <a href={robotc}><b> <u>Terms and Conditions</u> </b></a>. Please       review them carefully before proceeding
</p>

      <p className="text-white text-center py-3 m-0">
        MyEquation<sup>TM</sup>  is a registered trademark under Tech Analogy Pvt Ltd. All rights reserved | &#169;
        {/* <a className="foota" className="reserved" href="https://aashonline.com/">
          Aashonline
        </a> */}
      </p>
    </div>
  );
};

export default Footer;
