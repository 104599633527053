import React from "react";
import { Container, Image, Row, Col, Button } from 'react-bootstrap';
import Card from "react-bootstrap/Card";

import internship from "./../images/equlogo4.svg";
import internshipbg from "./../images/bg.jpg";
import internshiplogo1 from "./../images/internship/Group 168.png";
import internshiplogo2 from "./../images/internship/Group 169.png";
import internshiplogo3 from "./../images/internship/Group 170.png";
import internshiplogo4 from "./../images/internship/Group 171.png";
import internshiplogo5 from "./../images/internship/Group 172.png";
import internshiplogo6 from "./../images/internship/Group 174.png";
import internshiplogo7 from "./../images/internship/Group 175.png";
const Blog = () => {
    return (
        <>
            <Image src={internship} className="background-image " fluid />
            <Container className="rectangle">
                <Row className="float-right">
                    <Col md={6} className="content-blog">
                        <h3 className="text-white poppins">Integrate the power of curiosity with My Equation</h3>
                        <p className="paragraph-blog text-white poppins">Know the story behind our identity</p>
                        <a href="https://medium.com/@techanalogy/beyond-the-horizons-where-we-equate-your-career-85e617c89308" target="_blank" ><Button variant="btn btn-hover btn-warning" className="poppins">Why MyEquation</Button></a>
                    </Col>
                </Row>
            </Container>
        </>
        // <>
        //     <section className="my-5">
        //         <Container>
        //             <Row className="d-none d-md-flex">
        //                 <Col xs={12} md={7}>
        //                     <picture>
        //                         <source media="(max-width:576px)" srcset={internshipbg} />
        //                         <img src={internshipbg} alt="patners" className="bg-color" />
        //                     </picture>
        //                     <div className="internship-text">

        //                     </div>
        //                 </Col>
        //                 <Col xs={12} md={5} className="partner-text">
        //                     <h4 className="display-2 text-color poppins-semibold">
        //                         Internships & Opportunities
        //                     </h4>
        //                 </Col>
        //             </Row>



        //         </Container>
        //     </section>
        // </>
    );
};

export default Blog;
