import React from "react";
import Container from "react-bootstrap/Container";
import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Link } from "react-router-dom";
import bgcircle from "./../images/bgcircle.png";
import bg2 from "./../images/ww.png";
import icon1 from "./../images/icon/1.png";
import icon2 from "./../images/icon/2.png";
import icon3 from "./../images/icon/3.png";
import icon4 from "./../images/icon/4.png";
import icon5 from "./../images/icon/5.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { animateScroll as scroll } from 'react-scroll';
import Button from 'react-bootstrap/Button';
const TopBanner = () => {
  window.onscroll = function () {
    scrollRotate();
  };

  function scrollRotate() {
    let image = document.getElementById("reload");
    image.style.transform = "rotate(" + window.pageYOffset / 10 + "deg)";
  }
  const handleArrowClick = () => {
    scroll.scrollTo(window.innerHeight);
  };
  return (
    <>
      <div className="home-bg-banner">
        <Container className="banner-top-padding">
          <Row>
            <Col sm={12}>
              <h1 className="text-white main-text abb-voice">
                FIND THE EQUATION <br />
                OF YOUR CAREER
              </h1>
            </Col>
            <Col md={12} lg={6}>


              <h2 className="text-white main-h2 abb-voice">
                Platform for Vocational Training with 5x Better Experience!

              </h2>
              <p className="text-white main-p poppins-light">
                Expanding the learning horizon for CORE branches of Engineering
              </p>
              <div className="d-flex btn2 gap-3 mt-5">
                <a className="btn btn-hover btn-outline-secondary btn-color top-btn-p" href="https://forms.gle/SCLDDempeYn213w18">Get Started <i className="fa-solid fa-greater-than"></i></a>
                {/* <Link className="btn btn-hover btn-outline-secondary btn-color top-btn-p" to="/">
                  Explore more <i className="fa-solid fa-greater-than"></i>
                </Link> */}
                <div>
                  <div className="d-flex gap-3 align-items-center">
                  <div className="text-white main-h2 abb-voice">
                  JOIN OUR INDUSTRIAL <br />
                  TRAINING PROGRAM - <a className="ffroboai">ROBO AI</a>
                </div>
                
                    <a href="https://roboai.myequation.in/" target="_blank"><Button variant="btn btn-hover btn-warning lead me-4 top-btn-p">CHECK OUT NOW</Button></a>
                  </div>
                </div>
              </div>

            </Col>
            <Col md={12} lg={6} className="animation-div">
              <div className="relative">

                <img src={bgcircle} className="circle-width" id="reload" />

                <img src={bg2} className="img-absolute" />

              </div>
            </Col>
            {/* <Col md={12} lg={6} className="">
              <div className="relative">
             
                  <img src={bgcircle} className="circle-width" id="reload" />
                
                  <img src={bg2} className="img-absolute" />
                
              </div>
            </Col> */}
          </Row>
        </Container>

        <div className="absolute-b d-none d-lg-block">
          <img src={icon5} alt="" />

        </div>
        <div className="arrow-absolute d-none d-lg-block ">
          <i className="fa-solid fa-angles-down fa-2x text-white" onClick={handleArrowClick} />
        </div>


      </div>
    </>
  );
};

export default TopBanner;

