export const Data = {
    product: [
        {
            newcategory: 0,
            title: "Robo AI",
            topics: "Robotics and AI",
            description: "An innovative workshop that covers the fundamentals of the prevalent topic of Robotics and Artificial Intelligence in the most simplified manner possible. This personalised programme gives an opportunity to understand the field of Robotics and AI in depth and enhance practical insights and real-world applications under the guidance of expert mentors.",
            image: "./images/products/roboai.jpg",
            // rating: 4.5,
            link: "https://roboai.myequation.in/",
            reviews: "10 Reviews"
        },
        {
            newcategory: 0,
            title: "Robopack",
            topics: "Robotics automation and software simulation with AI ",
            description: "A 3-day industrial training robotics bootcamp that delves you into a fascinating world of Robotics and AI. It offers the expertise and knowledge you require to excel in your career",
            image: "./images/products/Robopack.jpg",
            // rating: 4.5,
            link: "https://robopack.myequation.in/",
            reviews: "10 Reviews",
            extra: ""
        },
        {
            newcategory: 1,
            title: "Robopack",
            topics: "Robotics, Software Simulation",
            description: "The Robopack Webinar pays tribute to NASA and showcases advanced robot manufacturing and software simulation. Join the webinar to explore the fascinating world of robotics and delve into the exciting realm of robots and automation.",
            image: "./images/products/robopack.png",
            rating: 4.1,
            link: "/products",
            reviews: "10 Reviews"
        },
        {
            newcategory: 1,
            title: "Placeopedia",
            topics: "OOPS, DBMS, Data Structures & Algorithms, Computer Networks, Operating Systems, Competitive Programming",
            description: "Enrol in our comprehensive training and placement bootcamp, designed to equip you with the skills needed to excel in interviews and secure positions at top MAANG companies. Benefit from expert mentors guiding you towards success.",
            image: "./images/products/placeopedia.png",
            rating: "4.0",
            link: "/products",
            reviews: "5 Reviews"
        },
        {
            newcategory: 1,
            title: "Robonetics 2.0",
            topics: "Robotics, Software Implementation",
            description: "In the Robonetics 2.0 workshop, learners are provided with comprehensive insights into the mechanics and operation of robots, equipping them with the knowledge and skills necessary for successfully implementing robots in various applications of the modern era.",
            image: "./images/products/robonetics.png",
            rating: 4.5,
            link: "/products",
            reviews: "5 Reviews"
        },
        {
            newcategory: 1,
            title: "Design Scape",
            topics: "UI/UX, Graphic Designing",
            description: "Design Scape offers collaborative, immersive coaching for individuals and teams, catering to all skill levels in product design, UI/UX, and graphic design. Elevate your abilities with this comprehensive training programme.",
            image: "./images/products/designscape.png",
            rating: 4.2,
            link: "/products",
            reviews: "5 Reviews"
        },
        {
            newcategory: 1,
            title: "Auto Drift",
            topics: "Automation, Matlab, Solidworks, Ansys",
            description: "Quick turns, high speed, and fancy cars, don’t we all love them? Autodrift is an intensive workshop providing knowledge about the basic functioning,designing and performance analysis of cars with Solidworks, Ansys, and Matlab.",
            image: "./images/products/autodrift.png",
            rating: 4.7,
            link: "/products",
            reviews: "5 Reviews"
        },
        {
            newcategory: 1,
            title: "DSA Masteerclass",
            topics: "Data Structures & Algorithms, Computer Networks, Operating Systems",
            description: "A complete, power-packed masterclass providing a roadmap towards MAANG. Get to know the secrets to becoming a Software Development Engineer with the most essential programming languages.",
            image: "./images/products/dsamaster.png",
            rating: 4.2,
            link: "/products",
            reviews: "5 Reviews"
        },
        {
            newcategory: 1,
            title: "Industry Automation",
            topics: "PLC , SCADA",
            description: "A comprehensive workshop focused on the fundamentals of fluid power automation and control systems specifically designed for industrial processes. The course extends the horizons of manufacturing processes with specialised skills and knowledge.",
            image: "./images/products/indauto.png",
            rating: 4.1,
            link: "/products",
            reviews: "5 Reviews"
        },
        {
            newcategory: 1,
            title: "Cloud Net",
            topics: "Cloud Computing",
            description: "Cloud Net is an intensive workshop focusing on cloud computing. Gain expertise in servers, networking, and analytics, and elevate your skills for the booming cloud market. Join now and excel in the cloud industry.",
            image: "./images/products/cloudnet.png",
            rating: 4.1,
            link: "/products",
            reviews: "5 Reviews"
        },
        {
            newcategory: 1,
            title: "Autogenix",
            topics: "SOLIDWORKS , ANSYS",
            description: "The AUTOGENIX workshop focuses on disseminating knowledge on autonomous cars using cutting-edge Solidworks and ANSYS with seasoned mentors. The workshop offers a unique learning environment, benefiting the learners with insight and guidance",
            image: "./images/products/Autogenix.png",
            rating: 4.2,
            link: "/products",
            reviews: "5 Reviews"
        },
        {
            newcategory: 2,
            title: "Stock Market & Trading",
            topics: "Stock Market, Investments, Advanced Algorithms, and Trading Techniques",
            description: "Join our exclusive workshop for a comprehensive toolkit with real-life market examples. Learn how to make strategic moves using cutting-edge algorithms and techniques to master the market. Don't miss this opportunity!",
            image: "./images/products/stockmarket.png",
            rating: 4.2,
            link: "/products",
            reviews: "5 Reviews"
        },

        {
            newcategory: 2,
            title: "Cyber Punk",
            topics: "Cyber Security, Ethical hacking",
            description: "An intense workshop on Cyber Security covering all methods of protecting against all digital attacks. A complete collection of interactive discussion with expert mentors and industry professionals bringing their insights on networks, data protection, computer systems, and disaster recovery.",
            image: "./images/products/cyberpunk.png",
            rating: 4.3,
            link: "/products",
            reviews: "5 Reviews"
        },

        {
            newcategory: 2,
            title: "Cognitive Application",
            topics: "Machine Learning, AI",
            description: "In today's fast-paced world, this workshop equips you with comprehensive knowledge on cognitive applications in Machine Learning and Artificial Intelligence, enabling you to navigate and succeed in the evolving landscape of technology.",
            image: "./images/products/cognitiveapp.png",
            rating: 4.2,
            link: "/products",
            reviews: "5 Reviews"
        },
        {
            newcategory: 3,
            title: "E-Drive",
            topics: "Automation, Mechanics, Aerodynamics, PCB Designing",
            description: "Join our exclusive workshop for a comprehensive toolkit with real-life market examples. Learn how to make strategic moves using cutting-edge algorithms and techniques to master the market. Don't miss this opportunity!",
            image: "./images/products/Edrive.png",
            rating: 4.8,
            link: "/products",
            reviews: "5 Reviews"
        },
        {
            newcategory: 3,
            title: "Robotron",
            topics: "Robotics, Software Simulation",
            description: "Robotron focuses on creating modular robot manufacturing devices, designing sophisticated simulation software, and leveraging industry experts' expertise to optimise resource utilisation and enhance overall efficiency in the production process.",
            image: "./images/products/robotron.png",
            rating: 4.5,
            link: "/products",
            reviews: "5 Reviews"
        },
        {
            newcategory: 3,
            title: "Pro Pack",
            topics: "Competitive Programming, Operating Systems, Data Structures & Algorithms",
            description: "Embark on a comprehensive crash course exploring the intricacies of programming. Unveil the mysteries of Computer Networks and delve into the world of Competitive Programming, all while mastering a multitude of other fascinating programming concepts. Let the journey begin!",
            image: "./images/products/procode.png",
            rating: 4.4,
            link: "/products",
            reviews: "5 Reviews"
        },
        {
            newcategory: 3,
            title: "Placeopedia",
            topics: "OOPS, DBMS, Data Structures & Algorithms, Computer Networks, Operating Systems, Competitive Programming",
            description: "Enrol in our comprehensive training and placement bootcamp, designed to equip you with the skills needed to excel in interviews and secure positions at top MAANG companies. Benefit from expert mentors guiding you towards success.",
            image: "./images/products/placeopedia.png",
            rating: 4.5,
            link: "/products",
            reviews: "5 Reviews"
        },
        {
            newcategory: 3,
            title: "Robonetics 2.0",
            topics: "Robotics, Software Implementation",
            description: "In the Robonetics 2.0 workshop, learners are provided with comprehensive insights into the mechanics and operation of robots, equipping them with the knowledge and skills necessary for successfully implementing robots in various applications of the modern era.",
            image: "./images/products/robonetics.png",
            rating: 4.2,
            link: "/products",
            reviews: "5 Reviews"
        },
        {
            newcategory: 3,
            title: "Design Scape",
            topics: "UI/UX, Graphic Designing",
            description: "Design Scape offers collaborative, immersive coaching for individuals and teams, catering to all skill levels in product design, UI/UX, and graphic design. Elevate your abilities with this comprehensive training programme.",
            image: "./images/products/designscape.png",
            rating: 4.2,
            link: "/products",
            reviews: "5 Reviews"
        },
        {
            newcategory: 3,
            title: "Auto Drift",
            topics: "Automation, Matlab, Solidworks, Ansys",
            description: "Quick turns, high speed, and fancy cars, don’t we all love them? Autodrift is an intensive workshop providing knowledge about the basic functioning,designing and performance analysis of cars with Solidworks, Ansys, and Matlab.",
            image: "./images/products/autodrift.png",
            rating: 4.1,
            link: "/products",
            reviews: "5 Reviews"
        },


        {
            newcategory: 3,
            title: "Mechnzie",
            topics: "CAD, Engineering Drawing",
            description: "The mechanisation journey encompasses automating every manufacturing process, from designing and assembling to creating models for real-life applications, leading to increased efficiency, precision, and productivity in the manufacturing industry.",
            image: "./images/products/mechnzie.png",
            rating: 4.2,
            link: "/products",
            reviews: "5 Reviews"
        },



    ],
    pastevents: [
        // {
        //     image: "./images/products/indauto.png",
        //     title: "Industry Automation",
        //     description: `Engineering Drawing is the root to every mechanical branch.TechAnalogy brings to you the second workshop on Autonomous Vehicle Foundation workshop, "AUTOGENIX" an interactive 6 days workshop where everything`,

        // },
        // {
        //     image: "./images/products/indauto.png",
        //     title: "Industry Automation",
        //     description: `Engineering Drawing is the root to every mechanical branch.TechAnalogy brings to you the second workshop on Autonomous Vehicle Foundation workshop, "AUTOGENIX" an interactive 6 days workshop where everything`,

        // }

    ],
    futureevents: [

    ]
}
