import React from "react";
import Container from "react-bootstrap/Container";
import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Link, useLocation } from "react-router-dom";


import card2 from "./../images/corporate/2card.png";
import p1 from "./../images/corporate/people/p1.png";
import p2 from "./../images/corporate/people/p2.png";
import p3 from "./../images/corporate/people/p3.png";

import c1 from './../images/threecard/c1.jpg';
import c2 from './../images/threecard/c2.jpg';
import c3 from './../images/threecard/c3.jpg';
import c4 from './../images/threecard/c4.jpg';
import c5 from './../images/threecard/c5.jpg';
import c6 from './../images/threecard/c6.jpg';
import c7 from './../images/threecard/c7.jpg';
import c8 from './../images/threecard/c8.jpg';
import c9 from './../images/threecard/c9.png';
import c10 from './../images/threecard/c10.jpg';
import c11 from './../images/threecard/c11.jpg';
import c12 from './../images/threecard/c12.jpg';

import cd1 from "./../images/corporate/4card/cd1.png";
import cd2 from "./../images/corporate/4card/cd2.png";
import cd3 from "./../images/corporate/4card/cd3.png";
import cd4 from "./../images/corporate/4card/cd4.png";

import l28 from "./../images/partnerlogo/l28.png";
import l29 from "./../images/partnerlogo/l29.png";
import l30 from "./../images/partnerlogo/l30.png";
import l31 from "./../images/partnerlogo/l31.png";
import l32 from "./../images/partnerlogo/l32.png";
import l33 from "./../images/partnerlogo/l33.png";
import l34 from "./../images/partnerlogo/l34.png";
import l35 from "./../images/partnerlogo/l35.png";
import l36 from "./../images/partnerlogo/l36.png";
import l37 from "./../images/partnerlogo/l37.png";
import l38 from "./../images/partnerlogo/l38.png";
import l39 from "./../images/partnerlogo/l39.png";
import l40 from "./../images/partnerlogo/l40.png";
import l43 from "./../images/partnerlogo/l43.png";
import l41 from "./../images/partnerlogo/l41.png";
import l42 from "./../images/partnerlogo/l42.png";

import b1 from "./../images/colleges/b1.png";
import b2 from "./../images/colleges/b2.png";
import b3 from "./../images/colleges/b3.png";
import b4 from "./../images/colleges/b4.png";
import b5 from "./../images/colleges/b5.png";
import b6 from "./../images/colleges/b6.png";
import b7 from "./../images/colleges/b7.png";
import b8 from "./../images/colleges/b8.png";
import b9 from "./../images/colleges/b9.png";
import b10 from "./../images/colleges/b10.png";
import b11 from "./../images/colleges/b11.png";
import b12 from "./../images/colleges/b12.png";
import b13 from "./../images/colleges/b13.png";
import b14 from "./../images/colleges/b14.png";
import b15 from "./../images/colleges/b15.png";
import b16 from "./../images/colleges/b16.png";
import b17 from "./../images/colleges/b17.png";
import b18 from "./../images/colleges/b18.png";
import b19 from "./../images/colleges/b19.png";
import b20 from "./../images/colleges/b20.png";
import b21 from "./../images/colleges/b21.png";
import b22 from "./../images/colleges/b22.png";
import b23 from "./../images/colleges/b23.png";
import b24 from "./../images/colleges/b24.png";
import b25 from "./../images/colleges/b25.png";
import b26 from "./../images/colleges/b26.png";
import b27 from "./../images/colleges/b27.png";
import Header from './../includes/Header';
import Slider from "react-slick";
import logo from './../images/ME logo 1.png';


const Corporate = () => {



  var settings = {
    dots: true,
    autoplay: true,
    infinite: true,
    autoplaySpeed: 0,
    speed: 7000,
    cssEase: 'linear',
    rows: 1,
    slidesToShow: 4,
    slidesToScroll: 1,
    initialSlide: 0,
    centerMode: true,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
        },
      },
    ],
  };

  var settings2 = {
    dots: true,
    infinite: true,

    rows: 1,
    autoplay: true,
    autoplaySpeed: 0,
    speed: 3000,
    cssEase: 'linear',
    centerMode: true,
    slidesToShow: 7,
    slidesToScroll: 1,
    initialSlide: 0,

    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          arrows: false,
        },
      },
    ],
  };
  return (
    <>
      <Header color="" logo={logo} />
      <section className="corporatebg">
        <Container className="ptb-135">
          <Row>
            <Col md={6}>
              <h5 className="corporate_color poppins">My Equation for business</h5>
              <h1 className="display-1 fw-bold corporate_color poppins">
                Skill Up and Scale Up
              </h1>
              <h5 className="corporate_color display-6 poppins">Transform your engineers <br /> into experts</h5>
              <div className="d-flex mt-5">
                <input
                  type="email"
                  className="form-control"
                  placeholder="Enter Email Address"
                />
                <button className="btn corporate_bg text-white mx-3">
                  Submit
                </button>
              </div>
            </Col>
            {/* <Col md={6}>
              <img src={card2} alt="" className="w-100" />
            </Col> */}
          </Row>
        </Container>

        <div className="bdr-2 mt-2 countbg">
          <Container className="mt-1  mb-4">
            <Row className="justify-content-center mt-1 gy-5">
              <Col md={3}>
                <p className="text-center fw-bold display-3 p-0 m-0 color-gray">
                  40K+
                </p>
                <p className="text-center fw-bold h5 p-0 m-0 color-gray">
                  Students Engaged
                </p>
              </Col>

              <Col md={3}>
                <p className="text-center fw-bold display-3 p-0 m-0 color-gray">
                  760+
                </p>
                <p className="text-center fw-bold h5 p-0 m-0 color-gray">
                  College Market
                </p>
              </Col>
              <Col md={3}>
                <p className="text-center fw-bold display-3 p-0 m-0 color-gray">
                  9K+
                </p>
                <p className="text-center fw-bold h5 p-0 m-0 color-gray">
                  Mentees Enrolled
                </p>
              </Col>
              <Col md={3}>
                <p className="text-center fw-bold display-3 p-0 m-0 color-gray">
                  1M+
                </p>
                <p className="text-center fw-bold h5 p-0 m-0 color-gray">
                  Social Media Outreach
                </p>
              </Col>
            </Row>
          </Container>
        </div>
      </section>
      {/* wht partners */}
      <section className="py-5">
        <div className="techbg">
          <Container>
            <div className="d-flex justify-content-center align-items-center">
              <div>
                <h1 className="fw-bold corporate_color text-center lh-45 poppins">
                  Why Partner with
                </h1>
                <h1 className="display-3 corporate_color text-center lh-45 poppins">

                  <span className="">MY</span>
                  <span> Equation</span>
                </h1>
              </div>
              <h1 className=" display-1 font-8rem fw-bold corporate_color">
                ?
              </h1>
            </div>
            <Row>
              <Col md={4} className="text-center">
                <img src={p1} alt="" className="w-100" />
                <p className=" radius-7 h5 poppins-semibold corporate_bg text-white py-4 px-4 max-content">
                  Delivery &
                  <br />
                  Customer Excellence
                </p>
              </Col>
              <Col md={4} className="text-center">
                <img src={p3} alt="" className="w-100" />
                <p className=" radius-7 h5 poppins-semibold corporate_bg text-white py-4 px-4 max-content">
                  Technology &
                  <br />
                  Domain Leadership
                </p>
              </Col>
              <Col md={4} className="text-center">
                <img src={p2} alt="" className="w-100" />
                <p className=" radius-7 h5 poppins-semibold corporate_bg text-white py-4 px-5 max-content">
                  Maximise
                  <br />
                  Your Productivity
                </p>
              </Col>
            </Row>
          </Container>
        </div>
      </section>

      <section className="bg-gray py-5">
        <p className="h2 fw-bold text-center text-white poppins">
          Achievements & Milestones
        </p>
        <p className="text-white text-center">
          / / / / / / / / / / / / / / / / / / / /
        </p>

        <Slider {...settings}>
          <Col xs={12} lg={4} md={4} className="relative mb-3">
            <img src={c1} alt="" className="w-100 border-round" />

          </Col>
          <Col xs={12} lg={4} md={4} className="relative mb-3">
            <img src={c2} alt="" className="w-100 border-round" />

          </Col>
          <Col xs={12} lg={4} md={4} className=" relative mb-3">
            <img src={c3} alt="" className="w-100 border-round" />
            {/* <p className='position1 h5 poppins'>Collaborations <br />with <span className='h3'>250 + Colleges</span> <br /><span className='f-10'>specialised Workshops, Bootcamps & Courses</span></p> */}
          </Col>
          <Col xs={12} lg={4} md={4} className="relative mb-3">
            <img src={c4} alt="" className="w-100 border-round" />

          </Col>
          <Col xs={12} lg={4} md={4} className="relative mb-3">
            <img src={c5} alt="" className="w-100 border-round" />

          </Col>
          <Col xs={12} lg={4} md={4} className="relative mb-3">
            <img src={c6} alt="" className="w-100 border-round" />
            {/* <p className='position h4 poppins-semibold'>In Association with <br />Telangana Govt.</p>
                <img src={govt} alt="" className='govt' /> */}
          </Col>


          <Col xs={12} lg={4} md={4} className="relative mb-3">
            <img src={c7} alt="" className="w-100 border-round" />

          </Col>
          <Col xs={12} lg={4} md={4} className="relative mb-3">
            <img src={c8} alt="" className="w-100 border-round" />

          </Col>
          <Col xs={12} lg={4} md={4} className="relative mb-3">
            <img src={c9} alt="" className="w-100 border-round" />

          </Col>
          <Col xs={12} lg={4} md={4} className="relative mb-3">
            <img src={c10} alt="" className="w-100 border-round" />

          </Col>
          <Col xs={12} lg={4} md={4} className=" relative mb-3">
            <img src={c11} alt="" className="w-100 border-round" />
            {/* <p className='position h4 poppins'>No. 1 Awarded <br />by Start-up Punjab</p>
                <img src={punjab} alt="" className='punjab' /> */}
          </Col>
          <Col xs={12} lg={4} md={4} className="relative mb-3">
            <img src={c12} alt="" className="w-100 border-round" />

          </Col>
        </Slider>
      </section>
      {/* industries */}

      <section className="mesh">
        <Container className="py-5">
          <div className="">
            <h2 className="color-gray fw-bold corporate_color text-center display-5 poppins">Trusted by Top Industries</h2>
          </div>
        </Container>
        <div className="my-5">


          <Slider {...settings2}>
            <div>
              <img src={l28} alt="" className="w-100 border bg-white radius-15 mt-5" />
            </div>
            <div>
              <img src={l29} alt="" className="w-100 border bg-white radius-15 " />
            </div>
            <div>
              <img src={l30} alt="" className="w-100 border bg-white radius-15 mt-5" />
            </div>
            <div>
              <img src={l31} alt="" className="w-100 border bg-white radius-15 " />
            </div>
            <div>
              <img src={l32} alt="" className="w-100 border bg-white radius-15 mt-5" />
            </div>
            <div>
              <img src={l33} alt="" className="w-100 border bg-white radius-15 " />
            </div>
            <div>
              <img src={l34} alt="" className="w-100 border bg-white radius-15 mt-5" />
            </div>
            <div>
              <img src={l35} alt="" className="w-100 border bg-white radius-15 " />
            </div>
            <div>
              <img src={l36} alt="" className="w-100 border bg-white radius-15 mt-5" />
            </div>
            <div>
              <img src={l37} alt="" className="w-100 border bg-white radius-15 " />
            </div>
            <div>
              <img src={l38} alt="" className="w-100 border bg-white radius-15 mt-5" />
            </div>
            <div>
              <img src={l39} alt="" className="w-100 border bg-white radius-15 " />
            </div>
            <div>
              <img src={l40} alt="" className="w-100 border bg-white radius-15 mt-5" />
            </div>
            <div>
              <img src={l41} alt="" className="w-100 border bg-white radius-15 " />
            </div>
            <div>
              <img src={l42} alt="" className="w-100 border bg-white radius-15 mt-5" />
            </div>
            <div>
              <img src={l43} alt="" className="w-100 border bg-white radius-15 " />
            </div>
          </Slider>
        </div>
      </section>
      {/* why choose techanalogy */}

      <section className="mb-5">

        <div className="hbg py-5">
          <h1 className="fw-bold corporate_color text-center lh-45 poppins">
            Why choose
          </h1>
          <h1 className="display-3 corporate_color text-center lh-45 poppins">

            <span className="S">MY</span>
            <span> Equation</span>
          </h1>
        </div>




        <Container>
          <Row className="gy-3">
            <Col sm={6} md={3}>
              <div className="relative">
                <img src={cd3} alt="" className="w-100" />
                <p className="h3 text-white cardbg4 text-center py-2">Virtual Labs &
                  <br />
                  Hands on session</p>
              </div>
            </Col>
            <Col sm={6} md={3}>
              <div className="relative">
                <img src={cd4} alt="" className="w-100" />
                <p className="h3 text-white cardbg4 text-center py-2">Live Industrial Training
                  <br />
                  & Interaction</p>
              </div>
            </Col>
            <Col sm={6} md={3}>
              <div className="relative">
                <img src={cd1} alt="" className="w-100" />
                <p className="h3 text-white cardbg4 text-center py-2">Young Mentor with
                  <br />
                  Industry Exposure</p>
              </div>
            </Col>
            <Col sm={6} md={3}>
              <div className="relative">
                <img src={cd2} alt="" className="w-100" />
                <p className="h3 text-white cardbg4 text-center py-2">Student Community
                  <br />
                  Engagement</p>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <section className="acardemic">
        <Container className="my-5">
          <div className="">
            <h2 className="color-gray fw-bold corporate_color text-center display-5 poppins">Academic Collaborators</h2>
          </div>
        </Container>
        <div className="my-5">


          <Slider {...settings2}>
            <div>
              <img src={b1} alt="" className="w-100 border radius-15" />
            </div>
            <div>
              <img src={b2} alt="" className="w-100 border radius-15 mt-5" />
            </div>
            <div>
              <img src={b3} alt="" className="w-100 border radius-15" />
            </div>
            <div>
              <img src={b4} alt="" className="w-100 border radius-15 mt-5 " />
            </div>
            <div>
              <img src={b5} alt="" className="w-100 border radius-15" />
            </div>
            <div>
              <img src={b6} alt="" className="w-100 border radius-15 mt-5" />
            </div>
            <div>
              <img src={b7} alt="" className="w-100 border radius-15 " />
            </div>
            <div>
              <img src={b8} alt="" className="w-100 border radius-15 mt-5" />
            </div>
            <div>
              <img src={b9} alt="" className="w-100 border radius-15" />
            </div>
            <div>
              <img src={b10} alt="" className="w-100 border radius-15 mt-5" />
            </div>
            <div>
              <img src={b11} alt="" className="w-100 border radius-15 " />
            </div>
            <div>
              <img src={b12} alt="" className="w-100 border radius-15 mt-5" />
            </div>
            <div>
              <img src={b13} alt="" className="w-100 border radius-15 " />
            </div>
            <div>
              <img src={b14} alt="" className="w-100 border radius-15 mt-5" />
            </div>
            <div>
              <img src={b15} alt="" className="w-100 border radius-15 " />
            </div>
            <div>
              <img src={b16} alt="" className="w-100 border radius-15 mt-5" />
            </div>
            <div>
              <img src={b17} alt="" className="w-100 border radius-15 " />
            </div>
            <div>
              <img src={b18} alt="" className="w-100 border radius-15 mt-5" />
            </div>
            <div>
              <img src={b19} alt="" className="w-100 border radius-15 " />
            </div>
            <div>
              <img src={b20} alt="" className="w-100 border radius-15 mt-5" />
            </div>
            <div>
              <img src={b21} alt="" className="w-100 border radius-15 " />
            </div>
            <div>
              <img src={b22} alt="" className="w-100 border radius-15 mt-5" />
            </div>
            <div>
              <img src={b23} alt="" className="w-100 border radius-15 " />
            </div>
            <div>
              <img src={b24} alt="" className="w-100 border radius-15 mt-5" />
            </div>
            <div>
              <img src={b25} alt="" className="w-100 border radius-15 " />
            </div>
            <div>
              <img src={b26} alt="" className="w-100 border radius-15 mt-5" />
            </div>
            {/* <div>
<img src={b27} alt="" className="w-100 border radius-15 " />
</div> */}
          </Slider>
        </div>
      </section>
    </>
  );
};

export default Corporate;
