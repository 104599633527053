import React from 'react'
import Container from "react-bootstrap/Container";
import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Link } from "react-router-dom";
const Coomingsoon = () => {
  return (<>
  <Row className='justify-content-center ptb-135'>
    <Col md={6}>
        <Card>
        <Card.Body>
           <h1 className="display-1 text-center">
           LMS Cooming Soon

           </h1>
           <Link to='/' className='btn btn-primary'>Back to Home</Link>
           <Link></Link>
            </Card.Body>
        </Card>
    </Col>
  </Row>
  
  </>
   
  )
}

export default Coomingsoon