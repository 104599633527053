import React from 'react'
import Container from "react-bootstrap/Container";
import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import card1 from './../images/card1.png';
import card2 from './../images/card2.png';
import card3 from './../images/card3.png';
import punjab from './../images/icon/punjab.png';
import govt from './../images/icon/govt.png';


import c1 from './../images/threecard/c1.jpg';
import c2 from './../images/threecard/c2.jpg';
import c3 from './../images/threecard/c3.jpg';
import c4 from './../images/threecard/c4.jpg';
import c5 from './../images/threecard/c5.jpg';
import c6 from './../images/threecard/c6.jpg';
import c7 from './../images/threecard/c7.jpg';
import c8 from './../images/threecard/c8.jpg';
import c9 from './../images/threecard/c9.png';
import c10 from './../images/threecard/c10.jpg';
import c11 from './../images/threecard/c11.jpg';
import c12 from './../images/threecard/c12.jpg';
import card8 from './../images/card8.png';
import Slider from "react-slick";
const ThreeCard = () => {
  var settings = {
    dots: true,
    infinite: true,
    autoplay: true,

    autoplaySpeed: 0,
    speed: 7000,

    cssEase: 'linear',
    rows: 1,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 0,

    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
        },
      },
    ],
  };
  return (
    <>
      <section className='py-5 z-index'>
        <Container>
          <Row>
            <Slider {...settings}>
              <Col xs={12} lg={4} md={4} className="relative mb-3">
                <img src={c1} alt="" className="w-100" />

              </Col>
              <Col xs={12} lg={4} md={4} className="relative mb-3">
                <img src={c2} alt="" className="w-100" />

              </Col>
              <Col xs={12} lg={4} md={4} className=" relative mb-3">
                <img src={c3} alt="" className="w-100" />
                {/* <p className='position1 h5 poppins'>Collaborations <br />with <span className='h3'>250 + Colleges</span> <br /><span className='f-10'>specialised Workshops, Bootcamps & Courses</span></p> */}
              </Col>
              <Col xs={12} lg={4} md={4} className="relative mb-3">
                <img src={c4} alt="" className="w-100" />

              </Col>
              <Col xs={12} lg={4} md={4} className="relative mb-3">
                <img src={c5} alt="" className="w-100" />

              </Col>
              <Col xs={12} lg={4} md={4} className="relative mb-3">
                <img src={c6} alt="" className="w-100" />
                {/* <p className='position h4 poppins-semibold'>In Association with <br />Telangana Govt.</p>
                <img src={govt} alt="" className='govt' /> */}
              </Col>


              <Col xs={12} lg={4} md={4} className="relative mb-3">
                <img src={c7} alt="" className="w-100" />

              </Col>
              <Col xs={12} lg={4} md={4} className="relative mb-3">
                <img src={c8} alt="" className="w-100" />

              </Col>
              <Col xs={12} lg={4} md={4} className="relative mb-3">
                <img src={c9} alt="" className="w-100" />

              </Col>
              <Col xs={12} lg={4} md={4} className="relative mb-3">
                <img src={c10} alt="" className="w-100" />

              </Col>
              <Col xs={12} lg={4} md={4} className=" relative mb-3">
                <img src={c11} alt="" className="w-100 " />
                {/* <p className='position h4 poppins'>No. 1 Awarded <br />by Start-up Punjab</p>
                <img src={punjab} alt="" className='punjab' /> */}
              </Col>
              <Col xs={12} lg={4} md={4} className="relative mb-3">
                <img src={c12} alt="" className="w-100" />

              </Col>
            </Slider>
          </Row>

        </Container>

      </section>
    </>
  )
}
export default ThreeCard;
