import React from "react";
import Container from "react-bootstrap/Container";
import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import "./../App.css";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckSquare, faCoffee } from "@fortawesome/fontawesome-free-solid";
import icon1 from "./../images/icon/c3.png";
import icon11 from "./../images/icon/c33.png";
import icon2 from "./../images/icon/c2.png";
import icon22 from "./../images/icon/c22.png";
import icon3 from "./../images/icon/c1.png";
import icon33 from "./../images/icon/c11.png";
import icon4 from "./../images/icon/c4.png";
import icon44 from "./../images/icon/c44.png";
import Slider from "react-slick";
// import { fontaws } from '@fortawesome/fontawesome-free';
var FontAwesome = require("react-fontawesome");
export const OurCourses = () => {
  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    autoplay: true,
    pauseOnHover: true,
    slidesToShow: 4,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 576,
        settings: {
          centerMode: true,
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
        },
      },
    ],
  };
  return (
    <>
      <section>
        <Container>
          <h2 className="text-center h1 my-5 title-color poppins-semibold">Our Courses</h2>
          {/* nishant */}
          <Slider {...settings}>
            <Col xs={12} lg={3} md={4}>
              <Card className="rounded-3 card-color card-hover border-0 corcard">
                <Card.Body>
                  <div className="d-flex justify-content-between height">
                    <img src={icon1} alt="" />


                    {/* <FontAwesomeIcon
                      icon="fa-solid fa-robot"
                      size="6x"
                      className="my-secondary-color"
                    /> */}
                    <i className="fa-solid fa-car-circle-bolt"></i>
                    <div>
                      <Link className="btn btn-primary bg-black rounded-12" to="/products">
                        <i className="fa-solid fa-arrow-down rotate-225 fa-1-8"></i>
                      </Link>
                    </div>
                  </div>
                  <Card.Title className="hover-title my-3 poppins-medium fs-24">Robotic</Card.Title>
                  <Card.Text className="my-secondary-color1 hover-title poppins fs-14">
                    Explore the fundamentals of Robotics from the root. Ace the
                    field of Robotics with the right conceptual understanding.
                    <br /> <br />
                    Get answers to your curiosity through informative session conducted by experts.

                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>

            <Col xs={12} lg={3} md={4}>
              <Card className="rounded-3 card-color card-hover border-0 corcard">
                {/* <FontAwesomeIcon icon={faCoffee} /> */}

                <Card.Body>


                  <div className="d-flex justify-content-between height">
                    {/* <i className="fa-solid fa-robot fa-4x my-secondary-color"></i> */}
                    {/* <FontAwesomeIcon
                      icon="fa-solid fa-car"
                      size="6x"
                      className="my-secondary-color"
                    /> */}
                    <img src={icon2} alt="" />
                    <div>
                      <Link className="btn btn-primary bg-black rounded-12" to="/products">
                        <i className="fa-solid fa-arrow-down rotate-225 fa-1-8"></i>
                      </Link>
                    </div>
                  </div>
                  <Card.Title className="hover-title my-3 poppins-medium fs-24">
                    Electric Vehicle
                  </Card.Title>
                  <Card.Text className="my-secondary-color1 hover-title poppins fs-14">
                    It's the Electric Vehicles era !!! Get ahead with this
                    course and learn all about EV and how they work.
                    <br /> <br />
                    Explore the world of EVs through recorded sessions with expert guidance.
                    <br /> <br />
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>

            <Col xs={12} lg={3} md={4}>
              <Card className="rounded-3 card-color card-hover border-0 corcard">
                {/* <FontAwesomeIcon icon={faCoffee} /> */}

                <Card.Body>


                  <div className="d-flex justify-content-between height">
                    {/* <i className="fa-solid fa-robot fa-4x my-secondary-color"></i> */}
                    {/* <FontAwesomeIcon
                      icon="fa-solid fa-plane"
                      size="6x"
                      className="my-secondary-color" style={{transform:"rotate(-45deg)"}}
                    /> */}
                    <img src={icon4} alt="" />

                    <div>
                      <Link className="btn btn-primary bg-black rounded-12" to="/products">
                        <i className="fa-solid fa-arrow-down rotate-225 fa-1-8"></i>
                      </Link>
                    </div>
                  </div>
                  <Card.Title className="hover-title my-3 poppins-medium fs-24">
                    Aeronautics
                  </Card.Title>
                  <Card.Text className="my-secondary-color1 hover-title poppins fs-14">
                    Come join us as we take off for a journey with aircrafts and
                    jets. You may open your seatbelts now as you are in safe
                    hands here.
                    <br /> <br />
                    Gain useful information through engaging sessions anytime and whenever.

                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>

            <Col xs={12} lg={3} md={4}>
              <Card className="rounded-3 card-color card-hover border-0 corcard">
                {/* <FontAwesomeIcon icon={faCoffee} /> */}

                <Card.Body>
                  <div className="height">


                    <div className="d-flex justify-content-between">
                      {/* <i className="fa-solid fa-robot fa-4x my-secondary-color"></i> */}
                      {/* <FontAwesomeIcon
                      icon="fa-solid fa-industry"
                      size="6x"
                      className="my-secondary-color"
                    /> */}
                      <img src={icon3} alt="" />
                      <div>
                        <Link className="btn btn-primary bg-black rounded-12" to="/products">
                          <i className="fa-solid fa-arrow-down rotate-225 fa-1-8"></i>
                        </Link>
                      </div>
                    </div>
                    <Card.Title className="hover-title my-3 poppins-medium fs-24">
                      Industry Automation
                    </Card.Title>
                    <Card.Text className="my-secondary-color1 hover-title poppins fs-14">
                      Understand the basics of building and designing systems using fluid power systems.
                      <br /> <br />
                      Learn and get trained from seasoned mentors.
                      <br /> <br />
                    </Card.Text>
                  </div>
                </Card.Body>
              </Card>
            </Col>
          </Slider>

        </Container>
      </section>
    </>
  );
};
