import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { Link, redirect } from "react-router-dom";
import { NavLink } from "react-router-dom";
import NavDropdown from 'react-bootstrap/NavDropdown';
import Offcanvas from 'react-bootstrap/Offcanvas';
import logo from './../images/ME logo white 1.png';
import logoblack from './../images/logoblack.png';
import Dropdown from 'react-bootstrap/Dropdown';
import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
function Header(props) {
  const [show, setShow] = useState(false);
  return (
    <>
      {['md'].map((expand) => (
        <Navbar key={expand} expand={expand} className="nav-absulote">
          <Container>
            <Link className="navbar-brand" to="/"><img src={props.logo} className="logo" /></Link>
            <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-${expand}`} className="border-0" />
            <Navbar.Offcanvas
              id={`offcanvasNavbar-expand-${expand}`}
              aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
              placement="end"
            >
              <Offcanvas.Header closeButton>
                <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${expand}`}>
                  <img src={logoblack} className="" />

                </Offcanvas.Title>
              </Offcanvas.Header>
              <Offcanvas.Body>
                <Nav className="justify-content-end flex-grow-1 pe-3 ">
                  <NavLink className={props.color + " me-3 nav-link hover-nav "} to="/">Home</NavLink>

                  <NavLink className={props.color + " me-3 nav-link hover-nav"} to="/whyus">Why Us?</NavLink>

                  <NavLink className={props.color + " me-3 nav-link hover-nav "} to="/products">Our Products</NavLink>
                  <NavLink className={props.color + " me-3 nav-link hover-nav "} to="/corporate">For Enterprise</NavLink>
                  <a className={props.color + " me-3 nav-link hover-nav "} href="#contact-us">Contact Us</a>



                </Nav>

                <a href="http://learn.myequation.in"><Button className="px-4" variant="primary">Login</Button></a>
                <a href="https://forms.gle/SCLDDempeYn213w18" target="_blank"><Button className="px-4 mx-4" variant="btn btn-hover btn-warning lead me-4 top-btn-p">Enroll Now</Button></a>
                {/* <Dropdown>
      <Dropdown.Toggle variant="primary" id="dropdown-basic">
      Login
      </Dropdown.Toggle>

      <Dropdown.Menu>
        <NavLink className="dropdown-item" to="/coomingsoon">Student Login</NavLink>
        <NavLink className="dropdown-item" to="/corporate">College Login</NavLink>
        <NavLink className="dropdown-item" to="/corporate">Corpotate Login</NavLink>
       
      </Dropdown.Menu>
    </Dropdown> */}
              </Offcanvas.Body>
            </Navbar.Offcanvas>
          </Container>
        </Navbar>
      ))}

      <Modal
        show={show}
        onHide={() => setShow(false)}
        dialogClassName="modal-90w"
        aria-labelledby="example-custom-modal-styling-title"
      >
        <Modal.Header closeButton>

        </Modal.Header>
        <Modal.Body>
          <p className='h3 poppins-medium py-5 text-center'>
            Forging LMS. Stay Tuned !
          </p>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default Header;
