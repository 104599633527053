
import { useLocation } from 'react-router-dom';
import React, { useState, useEffect, useRef } from 'react';
import { Container, Accordion, Card, Button } from 'react-bootstrap';
// import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import team from "./../images/whyus/team.png";
import f1 from "./../images/whyus/facts/f1.png";
import f2 from "./../images/whyus/facts/f2.png";
import f3 from "./../images/whyus/facts/f3.png";
import card1 from "./../images/whyus/5cards/card1.png";
import card2 from "./../images/whyus/5cards/card2.png";
import card3 from "./../images/whyus/5cards/card3.png";
import card4 from "./../images/whyus/5cards/card4.png";
import card5 from "./../images/whyus/5cards/card5.png";

import b1 from "./../images/colleges/b1.png";
import b2 from "./../images/colleges/b2.png";
import b3 from "./../images/colleges/b3.png";
import b4 from "./../images/colleges/b4.png";
import b5 from "./../images/colleges/b5.png";
import b6 from "./../images/colleges/b6.png";
import b7 from "./../images/colleges/b7.png";
import b8 from "./../images/colleges/b8.png";
import b9 from "./../images/colleges/b9.png";
import b10 from "./../images/colleges/b10.png";
import b11 from "./../images/colleges/b11.png";
import b12 from "./../images/colleges/b12.png";
import b13 from "./../images/colleges/b13.png";
import b14 from "./../images/colleges/b14.png";
import b15 from "./../images/colleges/b15.png";
import b16 from "./../images/colleges/b16.png";
import b17 from "./../images/colleges/b17.png";
import b18 from "./../images/colleges/b18.png";
import b19 from "./../images/colleges/b19.png";
import b20 from "./../images/colleges/b20.png";
import b21 from "./../images/colleges/b21.png";
import b22 from "./../images/colleges/b22.png";
import b23 from "./../images/colleges/b23.png";
import b24 from "./../images/colleges/b24.png";
import b25 from "./../images/colleges/b25.png";
import b26 from "./../images/colleges/b26.png";
import b27 from "./../images/colleges/b27.png";
import Header from './../includes/Header';
import logo from './../images/ME logo white 1.png';


import Slider from "react-slick";
// import Faqsection from "./Faqsection";

const Whyus = () => {
  // useEffect(() => {
  //   window.scrollTo(0, 0)
  // })
  const location = useLocation();
  const faqRef = useRef(null);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const scrollToFAQ = queryParams.get('scrollToFAQ');

    if (scrollToFAQ === 'true' && faqRef.current) {
      faqRef.current.scrollIntoView({ behavior: 'smooth' });
      // window.history.replaceState("/whyus");
    } else {
      window.scrollTo(0, 0);
    }
  }, [location.search]);
  const [activeIndex, setActiveIndex] = useState(null);

  const handleToggle = (index) => {
    setActiveIndex((prevIndex) => (prevIndex === index ? null : index));
  };
  const faqData = [
    {
      question: 'Will the courses be offered in online or offline mode?',
      answer:
        'Our courses are exclusively offered in online mode. As an EdTech startup, we are specialised in providing convenient and accessible learning experiences through our virtual platform. This means that you can get access to our courses at any time and anywhere.',
    },
    {
      question: 'Are there any eligibility criteria to access the platform?',
      answer:
        'No, there are no specific eligibility criteria to use our platform or to access our services. We believe in providing equal opportunities for learning to all individuals, regardless of their background or qualifications.',
    },
    {
      question: 'Can I enroll in these courses while studying in college?',
      answer:
        'Absolutely! Our courses are designed in a manner that they are flexible and accessible, allowing you to pursue them alongside your college studies. Our aim is to support your educational goals and provide you with additional resources and knowledge to expand your learning experience.',
    },
    {
      question: 'What are the perks of enrolling in your courses?',
      answer:
        'In addition to providing premium educational facilities, we help students with internships, facilitate them with career counseling, and provide 1:1 doubt-clearing sessions. We also offer exclusive CV-building sessions to help you land your dream job.',
    },
    {
      question: 'Do these courses have any recognized collaborators?',
      answer:
        'We have partnerships with many colleges and industries which aim to provide you with the most accurate skills or understanding of a specific subject of STEM. To name some of them, we have collaborated with Heriot-Watt University, Christ University of Banglore, Trinity College Dublin, DDU College, Asia Pacific University, IIM Shillong, and many more.',
    },
    {
      question: 'Can I interact with my mentors and other learners on the platform?',
      answer:
        'Yes, absolutely! We value the importance of collaboration and interaction in the learning process. On our platform, we provide opportunities for learners to connect and engage with both instructors and fellow learners.',
    },
    {
      question: 'Can I track my progress or performance on your platform?',
      answer:
        'Yes, you can track the performance, as well as, the pace of your journey with the course. ',
    },
    {
      question: 'Are these courses available globally?',
      answer:
        'We are proud to offer our services worldwide. Our goal is to make quality education accessible to learners across the globe. Regardless of your geographic location, you can access and benefit from our educational services.',
    },
    {
      question: 'Are there any additional resources available to enhance the learning experience?',
      answer:
        'We host many interactive workshops and boot camps where students are given tasks on the subject of the workshop and then the mentors guide them accordingly.',
    },
    {
      question: 'Is your platform accessible on multiple devices?',
      answer:
        'Yes, absolutely! We understand the importance of flexibility and convenience in accessing educational resources. Our platform is designed to be responsive and accessible across various devices, including desktop computers, laptops, tablets, and mobile phones.',
    },


    // Add more FAQ items as needed
  ];

  var settings = {
    dots: true,
    autoplay: true,
    infinite: true,
    autoplaySpeed: 0,
    speed: 2000,
    cssEase: 'linear',
    useTransform: false,
    rows: 2,
    centerMode: true,
    slidesToShow: 7,
    slidesToScroll: 1,
    initialSlide: 0,

    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          arrows: false,
        },
      },
    ],
  };
  return (
    <>
      <Header color="white-768" logo={logo} />
      <div className=" ptb-whyus bgwhyus">
        <Container>
          <Row>
            <Col md={9}>
              <h1 className="text-white whyus-text abb-voice">

                Calculate your equation to limitless potential

              </h1>
              <Row>
                <Col md={9}>
                  <p className="text-white pt-3 h5">
                    A trusted STEM facilitator for core engineers. Creating a 360&deg; ecosystem for the learners out there.
                    Focused on constructing your career in the Tech Industry.

                  </p>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </div>

      {/* count */}

      <Container>
        <Row className="justify-content-center mt-1 gy-5">
          <Col md={3}>
            <p className="text-center fw-bold display-3 p-0 m-0 color-gray">
              40K+
            </p>
            <p className="text-center fw-bold h5 p-0 m-0 color-gray">
              Students Engaged
            </p>
          </Col>

          <Col md={3}>
            <p className="text-center fw-bold display-3 p-0 m-0 color-gray">
              760+
            </p>
            <p className="text-center fw-bold h5 p-0 m-0 color-gray">
              College Market
            </p>
          </Col>
          <Col md={3}>
            <p className="text-center fw-bold display-3 p-0 m-0 color-gray">
              9K+
            </p>
            <p className="text-center fw-bold h5 p-0 m-0 color-gray">
              Mentees Enrolled
            </p>
          </Col>
          <Col md={3}>
            <p className="text-center fw-bold display-3 p-0 m-0 color-gray">
              1M+
            </p>
            <p className="text-center fw-bold h5 p-0 m-0 color-gray">
              Social Media Outreach
            </p>
          </Col>
        </Row>
      </Container>

      {/* we */}
      <Container className="mt-5">
        <div className="single-p-bg radius-15 p-5">
          <h2 className="text-white text-center fw-bold">Who Are We?</h2>
          <div className="border-2px mt-2 mb-4"></div>
          <p className="text-white">
            With the motto to build a community of students and provide them with resources
            to find their way in the world through workshops and boot camps, "My Equation" gives
            the next generation a platform to innovate, get inspired, and come up with ideas. It
            was founded at SRM Institute of Science and Technology. We, at "My Equation" also aim to
            provide top-notch educational facilities to students around the world so that they can learn the
            materials and be more knowledgeable.
          </p>
        </div>
      </Container>
      {/* 5 card */}
      <section >
        <Container className="mt-5">
          <div className="border-2px-gray ">
            <h2 className="color-gray fw-bold">What do we do?</h2>
          </div>
          <Row className="justify-content-center gy-4 mt-4">

            <Col md={4} className='d-flex align-items-center justify-content-center'>
              <img src={card1} alt="" className="" />
            </Col>
            <Col md={4} className='d-flex align-items-center justify-content-center'>
              <img src={card2} alt="" className="" />
            </Col>
            <Col md={4} className='d-flex align-items-center justify-content-center'>
              <img src={card3} alt="" className="" />
            </Col>
          </Row>
        </Container>


      </section>
      <section className="whyus-card-bg pb-80">
        <Container>
          <Row className="justify-content-center gy-4 mb-4 mt-2">
            <Col md={4} className='d-flex align-items-center justify-content-center'>
              <img src={card4} alt="" className="" />
            </Col>
            <Col md={4} className='d-flex align-items-center justify-content-center'>
              <img src={card5} alt="" className="" />
            </Col>

          </Row>
        </Container>
      </section>

      <section className="">
        <Container className="my-5">
          <div className="">
            <h2 className="color-gray fw-bold display-4">College & <br />
              Institution partners</h2>
          </div>
        </Container>
        <div className="my-5">


          <Slider {...settings}>
            <div>
              <img src={b1} alt="" className="w-100 border radius-15" />
            </div>
            <div>
              <img src={b2} alt="" className="w-100 border radius-15 mt-5" />
            </div>
            <div>
              <img src={b3} alt="" className="w-100 border radius-15 mt-5" />
            </div>
            <div>
              <img src={b4} alt="" className="w-100 border radius-15 mt-5 " />
            </div>
            <div>
              <img src={b5} alt="" className="w-100 border radius-15" />
            </div>
            <div>
              <img src={b6} alt="" className="w-100 border radius-15 mt-5" />
            </div>
            <div>
              <img src={b7} alt="" className="w-100 border radius-15 mt-5" />
            </div>
            <div>
              <img src={b8} alt="" className="w-100 border radius-15 mt-5" />
            </div>
            <div>
              <img src={b9} alt="" className="w-100 border radius-15" />
            </div>
            <div>
              <img src={b10} alt="" className="w-100 border radius-15 mt-5" />
            </div>
            <div>
              <img src={b11} alt="" className="w-100 border radius-15 mt-5" />
            </div>
            <div>
              <img src={b12} alt="" className="w-100 border radius-15 mt-5" />
            </div>
            <div>
              <img src={b13} alt="" className="w-100 border radius-15 " />
            </div>
            <div>
              <img src={b14} alt="" className="w-100 border radius-15 mt-5" />
            </div>
            <div>
              <img src={b15} alt="" className="w-100 border radius-15 mt-5" />
            </div>
            <div>
              <img src={b16} alt="" className="w-100 border radius-15 mt-5" />
            </div>
            <div>
              <img src={b17} alt="" className="w-100 border radius-15 " />
            </div>
            <div>
              <img src={b18} alt="" className="w-100 border radius-15 mt-5" />
            </div>
            <div>
              <img src={b19} alt="" className="w-100 border radius-15 mt-5" />
            </div>
            <div>
              <img src={b20} alt="" className="w-100 border radius-15 mt-5" />
            </div>
            <div>
              <img src={b21} alt="" className="w-100 border radius-15 " />
            </div>
            <div>
              <img src={b22} alt="" className="w-100 border radius-15 mt-5" />
            </div>
            <div>
              <img src={b23} alt="" className="w-100 border radius-15 mt-5" />
            </div>
            <div>
              <img src={b24} alt="" className="w-100 border radius-15 mt-5" />
            </div>
            <div>
              <img src={b25} alt="" className="w-100 border radius-15 " />
            </div>
            <div>
              <img src={b26} alt="" className="w-100 border radius-15 mt-5" />
            </div>
            <div>
              <img src={b27} alt="" className="w-100 border radius-15 mt-5" />
            </div>

          </Slider>
        </div>
      </section>
      {/* partners */}

      {/* facts */}

      <div className="single-p-bg pt-3">
        <Container>
          <h5 className="h1 fw-bold text-white">Facts & Figures</h5>
          <div className="border-2px mt-1 pb-1"></div>
          <Row className="mt-3">
            <Col sm={4} md={3}>
              <img src={f2} alt="" className="w-100px mb-2" />
              <h5 className="h3 text-white fw-bold">Secured First</h5>

              <ul>
                <li className="text-white">
                  No.1 position in the “IEEE Innovation and Ideation Challenge”
                </li>
              </ul>
            </Col>
            <Col sm={4} md={3}>
              <img src={f1} alt="" className="w-100px mb-2" />
              <h5 className="h3 text-white fw-bold">Incubation Offers</h5>

              <ul>
                <li className="text-white">
                  5+ Incubation offers from multiple-tier Incubators
                </li>
              </ul>
            </Col>
            <Col sm={4} md={3}>
              <img src={f3} alt="" className="w-100px mb-2" />
              <h5 className="h3 text-white fw-bold">Vast Team</h5>

              <ul>
                <li className="text-white">
                  600+ members working for the Ed- Tech Startup
                </li>
              </ul>
            </Col>
            <Col sm={4} md={3}>
              <img src={f3} alt="" className="w-100px mb-2" />
              <h5 className="h3 text-white fw-bold">Learning Platform</h5>

              <ul>
                <li className="text-white">
                  10000+ students in the learning platform
                </li>
              </ul>
            </Col>
            <Col sm={4} md={3}>
              <img src={f2} alt="" className="w-100px mb-2" />
              <h5 className="h3 text-white fw-bold">Top Collaborations</h5>

              <ul>
                <li className="text-white">
                  Collaborations with the MAANG and other Top MNCs
                </li>
              </ul>
            </Col>
            <Col sm={4} md={3}>
              <img src={f1} alt="" className="w-100px mb-2" />
              <h5 className="h3 text-white fw-bold">Backed By</h5>

              <ul>
                <li className="text-white">
                  Backed up by one of India's largest Ed Tech Accelerators
                </li>
              </ul>
            </Col>
            <Col sm={4} md={3}>
              <img src={f3} alt="" className="w-100px mb-2" />
              <h5 className="h3 text-white fw-bold">Global Incubations</h5>

              <ul>
                <li className="text-white">
                  Received 5+ incubation offers from multiple tier incubators
                  across the globe
                </li>
              </ul>
            </Col>
            <Col sm={4} md={3}>
              <img src={f3} alt="" className="w-100px mb-2" />
              <h5 className="h3 text-white fw-bold">Startup Punjab</h5>

              <ul>
                <li className="text-white">
                  Awarded 1st Prize for INNOVATION by Startup Punjab
                </li>
              </ul>
            </Col>
            <Col sm={4} md={3}>
              <img src={f2} alt="" className="w-100px mb-2" />
              <h5 className="h3 text-white fw-bold">Secured Second</h5>

              <ul>
                <li className="text-white">
                  Secured 2nd position in “IEEE Innovation and Ideation
                  Challenge”

                </li>
              </ul>
            </Col>
            <Col sm={4} md={3}>
              <img src={f1} alt="" className="w-100px mb-2" />
              <h5 className="h3 text-white fw-bold">Recognized by</h5>

              <ul>
                <li className="text-white">
                  Recognised by the startup India
                  Initiative by the DPIIT
                </li>
              </ul>
            </Col>
          </Row>
        </Container>
      </div>
      {/* team */}
      <Container className="mt-5 mb-5">
        <h2 className="color-gray poppins-bold display-5 mt-2 mb-5">
          "Our Team"
        </h2>

        <Row>
          <Col md={6}>
            <h4 className="l-1">
              Our team at "My Equation" is a tightly knit group of all the passionate individuals with a shared objective of achieving consistently excellent results as well as having fun in the process, welcoming, and growing. Made up of creative and enthusiastic individuals with a fusion of various backgrounds and defined futuristic ideologies. "My Equation" aims to provide top-notch products with a reliance on cutting-edge technology. It's our secret recipe for an unsolved equation.

            </h4>
          </Col>
          <Col md={6}>
            <img src={team} alt="" className="w-100" />
          </Col>
        </Row>
      </Container>

      <Container className="mt-5 mb-5" id="faq" ref={faqRef}>
        <h2 className="color-gray poppins-bold display-5 mt-2 mb-5">
          FAQs
        </h2>
        <div className="faq-section">

          {faqData.map((item, index) => (
            <div className="faq-item" key={index}>
              <div className="question" onClick={() => handleToggle(index)}>
                <h4 className="question-text">{item.question}</h4>
                <span className={`toggle-icon ${activeIndex === index ? 'active' : ''}`}>
                  {activeIndex === index ? '-' : '+'}
                </span>
              </div>
              {activeIndex === index && (
                <div className="answer">
                  <p className="answer-text">{item.answer}</p>
                </div>
              )}
            </div>
          ))}
        </div>
      </Container>
    </>
  );
};

export default Whyus;
