import React from "react";
import Container from "react-bootstrap/Container";
import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import profileimg from "./../images/product/profile.jpg";

import AN from "./../images/profile/9.jpg";
import AP from "./../images/profile/11.jpg";
import DN from "./../images/profile/2.jpg";
import JB from "./../images/profile/4.jpg";
import MV from "./../images/profile/5.jpg";
import RB from "./../images/profile/3.jpg";
import SA from "./../images/profile/7.jpg";
import SK from "./../images/profile/8.jpg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Slider from "react-slick";
const Review = () => {
  var settings = {
    dots: true,
    infinite: true,
    speed: 200,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 1500,
    pauseOnHover: true,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          autoplay: true,
          pauseOnHover: true,
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          autoplay: true,
          pauseOnHover: true,
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <>
      <Container className="mb-5">
        <h2 className="text-center h1 my-5 title-color poppins-semibold">Hear from our mentees !</h2>
        <Row className="d-md">
          <Slider {...settings}>
            <Col sm={12} md={3} className=" mb-3">
              <div className="bg-white p-4 radius-7 review-hover border">
                <div className="d-flex align-items-center">
                  <img className="height-50" src={AP} alt="" />
                  <div className="px-3">
                    <h5 className="fs-5 mb-0 mt-2 lead poppins-medium">Adarsh Priyaranjan</h5>
                    <p className="m-0 poppins-light">NIT Rourkela</p>

                  </div>
                </div>
                <p className="">
                  Hi, I am Aadarsh and I attended the 3-month DSA course from Tech Analogy .
                  It was a wonderful experience for me as I don't have any prior knowledge of any
                  computer language but the team first provided us with lessons on C++ language before starting the DSA course.
                  The entire DSA course was well structured and explanations of difficult topics
                  happen regularly, doubt sessions were great. The Google Classroom helped me a lot as I used to see the recorded lectures due to engagement in my college activities. I would also like to thank Kaustuv Sir for always being present in the meeting for
                  attending to queries.

                </p>
              </div>
            </Col>
            <Col sm={12} md={6} className="  ">
              <div className="bg-white p-4 radius-7 review-hover border">
                <div className="d-flex align-items-center">
                  <img className="height-50" src={SK} alt="" />
                  <div className="px-3">
                    <h5 className="fs-5 mb-0 mt-2 lead poppins-medium">Sruthi Kairam</h5>
                    <p className="m-0 poppins-light">BITS Pilani </p>

                  </div>
                </div>
                <p className="">
                  I chose techanalogy due to the wonderful opportunities it had to offer after completing the course. The lectures were very interactive and the mentors were
                  quite helpful in solving in every doubt.The seperate doubt sessions proved to be pretty helpful. Overall it's a great experience to be part of this workshop.

                </p>

              </div>
            </Col>
            <Col sm={12} md={6} className="  ">
              <div className="bg-white p-4 radius-7 review-hover border">
                <div className="d-flex align-items-center">
                  <img className="height-50" src={MV} alt="" />
                  <div className="px-3">
                    <h5 className="fs-5 mb-0 mt-2 lead poppins-medium">Manav</h5>
                    <p className="m-0 poppins-light">MIT-WPU</p>
                  </div>
                </div>
                <p className="">
                  I attended Tech Analogy's Robotron Workshop, which was a wonderful experience. The mentors were knowledgeable and had regular question-and-answer sessions, which tremendously aided the learning process. I liked how the programme focused on industry training rather than just general certification. The 45-day workshop provided enough time to cover all of the basic areas of the industry while also allowing participants to dig deeper into topics of personal interest. This kind of instruction ensured a well-rounded and engaging learning experience.
                </p>

              </div>
            </Col>
            <Col sm={12} md={6} className="  ">
              <div className="bg-white p-4 radius-7 review-hover border">
                <div className="d-flex align-items-center">
                  <img className="height-50" src={DN} alt="" />
                  <div className="px-3">
                    <h5 className="fs-5 mb-0 mt-2 lead poppins-medium">Dhananjay</h5>
                    <p className="m-0 poppins-light">SRM KTR</p>

                  </div>
                </div>
                <p className="">
                  I am Dhananjay, a first year student doing a b.tech in mechatronics with a specialisation in robotics at SRMIST, Kattankulathur. When I first saw this workshop, I was curious, then I went through the curriculum and I saw that all the content was carefully prepared and put into consideration for what is needed for an all round experience in the field of robotics. I was eager and registered. And I was not disappointed. Each and every mentor taught the topics well in an understandable format, and many additional materials were provided to explore more. All the mentors cleared all our doubts and explained things that are relevant to the subject. Finally, the assignments helped to test us on what to be done and see how things are implemented
                </p>

              </div>
            </Col>
            <Col sm={12} md={6} className="  ">
              <div className="bg-white p-4 radius-7 review-hover border">
                <div className="d-flex align-items-center">
                  <img className="height-50" src={RB} alt="" />
                  <div className="px-3">
                    <h5 className="fs-5 mb-0 mt-2 lead poppins-medium">Rishabh Bothra
                    </h5>
                    <p className="m-0 poppins-light">Amity University, Rajasthan</p>

                  </div>
                </div>
                <p className="">
                  I learned about Tech Analogy in my college and joined their DSA program to improve my problem-solving skills which would help me in my placements. It was an enriching experience for a few months where they covered everything in detail and depth. The lectures were to the point and covered a lot of question-solving.
                  All the mentors were very helpful and responsive to the student's needs. All
                  in all, it was a wonderful experience throughout and for that I would the thank
                  the Tech Analogy team.

                </p>

              </div>
            </Col>
            <Col sm={12} md={6} className="  ">
              <div className="bg-white p-4 radius-7 review-hover border">
                <div className="d-flex align-items-center">
                  <img className="height-50" src={AN} alt="" />
                  <div className="px-3">
                    <h5 className="fs-5 mb-0 mt-2 lead poppins-medium">Ananya

                    </h5>
                    <p className="m-0 poppins-light">Chitkara University</p>

                  </div>
                </div>
                <p className="">
                  Overall, the Robotron workshop was an incredibly insightful and valuable experience. The workshop provide a comprehensive overview of robot development, from the fundamentals to more advanced concepts using different platforms. The sessions were well-planned and executed. I found the workshop material to be well-prepared and organized, this gave me a chance to review the topics that wanted revision. The plus point was that the assignment dates were extended which helped in managing the university curriculum along with
                  workshop. Furthermore, the teachers were dedicated and patient which helped in creating a positive and inclusive learning environment. They tried to clear and cover every minute detail and query which was a great help in understanding the concepts. The workshop was really a rewarding and enriching learning experience.
                </p>

              </div>
            </Col>
            <Col sm={12} md={6} className="  ">
              <div className="bg-white p-4 radius-7 review-hover border">
                <div className="d-flex align-items-center">
                  <img className="height-50" src={JB} alt="" />
                  <div className="px-3">
                    <h5 className="fs-5 mb-0 mt-2 lead poppins-medium">Jayanth Bantupalli
                    </h5>
                    <p className="m-0 poppins-light">Thapar Institute of Engineering and Technology</p>

                  </div>
                </div>
                <p className="">
                  Hey, I'm Jayant currently pursuing mechanical degree from Thapar.As a mechanical engineer, opportunities were
                  fewer in the job market, and even their scope of improvement wasn't quite good. | was so, decided to exit the being in the core field only and explore new things.
                  Then, I came across the Robotron workshop by Tech Analogy. Initially, my intentions were to get exposed to robotics as a field.
                  However, the workshop contained more than I expected, and one of the great is that within one & half months, | learned various areas involving robotics. The best part about the workshop was that it provided only industrial
                  required skills like the things popular in the industry. More importantly, | got the idea of exploring a specific topic rather than learning everything from scratch. Your content and course curriculum was excellent.


                </p>

              </div>
            </Col>
            <Col sm={12} md={6} className="  ">
              <div className="bg-white p-4 radius-7 review-hover border">
                <div className="d-flex align-items-center">
                  <img className="height-50" src={SA} alt="" />
                  <div className="px-3">
                    <h5 className="fs-5 mb-0 mt-2 lead poppins-medium">Shauraya Aggarwal
                    </h5>
                    <p className="m-0 poppins-light">Thapar Institute of Engineering and Technology</p>

                  </div>
                </div>
                <p className="">
                  Learning from the Tech Analogy’s Robotron workshop was an amazing experience for me. The experience was truly exceptional and exceeded all my expectations.
                  First and foremost, | was thoroughly impressed by the level of expertise and knowledge demonstrated by the workshop instructors. Their passion for robotics was contagious, and they did
                  a fantastic job of explaining complex concepts in an accessible manner.
                  Their patience and willingness to answer questions made the learning process enjoyable and engaging.l am immensely grateful for the opportunity to participate in this robotics workshop.
                  The knowledge and skills I gained will undoubtedly have a lasting impact on my personal and professional growth


                </p>

              </div>
            </Col>
          </Slider>
        </Row>
      </Container>
    </>
  );
};

export default Review;
