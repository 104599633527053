import { useLocation } from 'react-router-dom';
import React, { useState, useEffect, useRef } from 'react';
import Container from "react-bootstrap/Container";
import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
// import Button from "react-bootstrap/Button";
// import Stack from "react-bootstrap/Stack";
// import industrial from "./../images/product/Industry automation-01.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import TwoColumnProduct from "./TwoColumnProduct";
import { Link } from "react-router-dom";
// import { baseurl } from "./Config";
import productpage from "./../images/productpage.png";

import Nav from 'react-bootstrap/Nav';

import Tab from 'react-bootstrap/Tab';
import './../product.css';
import Header from './../includes/Header';
import logo from './../images/ME logo white 1.png';
const ProductPage = (props) => {

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const [cat, setCat] = useState(queryParams.get('cat'))

  useEffect(() => {
    window.scrollTo(0, 0);

  });


  return (
    <>
      <Header color="white-768" logo={logo} />
      <div className="single-p-bg mb-5 relative">
        <Row className="product-img">
          <Col sm={12} md={4}>
          </Col>
          <Col sm={12} md={8} className="text-right">
            <img src={productpage} alt="" className="p-img" />
          </Col>
        </Row>
        <Container className="banner-top-padding pb-5">
          <Row>
            <Col sm={12} md={6}>
              <h3 className="text-white poppins-bold display-4">Our Products</h3>
              <p className="text-white h2 pt-5 poppins">
                Find the <span className="h1 poppins-bold">Equation</span> of your
                career
              </p>
              <p className="text-white pt-5 h5">
                Strengthen your equation with effective tools using a combination of cutting-edge technology courses, learning from industrial experts, and excellent education materials.
              </p>

            </Col>
            <Col sm={12} md={6}>

            </Col>
          </Row>
        </Container>
      </div>

      <Container>
        {/* new */}
        <Tab.Container id="left-tabs-example" defaultActiveKey={cat === "1" ? "thirt" : "zeroth"}>
          <Row>
            <Col sm={3}>
              <Nav variant="pills" className="flex-column">
                <Nav.Item style={{ "zIndex": 9 }}>
                  <Nav.Link eventKey="zeroth">Upcoming Workshops</Nav.Link>
                </Nav.Item>
                <Nav.Item style={{ "zIndex": 9 }}>
                  <Nav.Link eventKey="thirt">Past Industrial Training</Nav.Link>
                </Nav.Item>
                <Nav.Item style={{ "zIndex": 9 }}>
                  <Nav.Link eventKey="second"> Past Workshops</Nav.Link>
                </Nav.Item>
                <Nav.Item style={{ "zIndex": 9 }}>
                  <Nav.Link eventKey="first"> Past Webinars & Bootcamps</Nav.Link>
                </Nav.Item>
                {/* new
                <Nav.Item style={{ "zIndex": 9 }}>
                  <Nav.Link eventKey="forth">Elective Courses/Curriculum <i className="fa-solid fa-lock text-warning"></i></Nav.Link>
                </Nav.Item>
                <Nav.Item className="mb-5" style={{ "zIndex": 9 }}>
                  <Nav.Link eventKey="fifth">Micro Degrees <i className="fa-solid fa-lock text-warning"></i></Nav.Link>
                </Nav.Item> */}
              </Nav>
            </Col>
            <Col sm={9}>
              <Tab.Content>
                <Tab.Pane eventKey="zeroth">
                  <Row className="gy-3 gx-md-3 mb-5 card-label1">
                    {props.product.map((productdata, index) => {
                      if (productdata.newcategory === 0) {
                        return (
                          <>
                            <Col xs={12} md={4} key={index}>
                              <Card className="card-label">
                                <div className="c-d-1"></div>
                                <a className="link" target="_blank" href={productdata.link}>
                                  <Card.Img
                                    variant="top"
                                    src={productdata.image}
                                  />
                                  <Card.Body>
                                    <Card.Title className="poppins-semibold text-black fs-24">
                                      {productdata.title} {
                                        productdata.extra &&
                                        <b className="free poppins">{`(${productdata.extra})`}</b>
                                      }
                                    </Card.Title>
                                    <Card.Text className="text-black poppins-light fs-14">
                                      <b className="fw-topics poppins-semibold">Topics Covered:</b> {productdata.topics}
                                    </Card.Text>
                                    <Card.Text className="text-black poppins-light fs-14">
                                      {productdata.description}
                                    </Card.Text>
                                    <Card.Text>
                                      <FontAwesomeIcon
                                        icon="fa-solid fa-star"
                                        beat
                                        className="text-warning"
                                      />
                                      {/* <span className="fw-bold poppins-light text-black">
                                        {productdata.rating + " "}
                                      </span> */}
                                      <span className="text-black poppins-light">

                                        ({productdata.short_desc} Reviews)
                                      </span>
                                    </Card.Text>
                                  </Card.Body>
                                </a>
                              </Card>
                            </Col>
                          </>
                        );
                      }
                    })}


                  </Row>
                </Tab.Pane>
                <Tab.Pane eventKey="first">
                  <Row className="gy-3 gx-md-3 mb-5 card-label1">
                    {props.product.map((productdata, index) => {
                      if (productdata.newcategory === 1) {


                        return (
                          <>
                            <Col xs={12} md={4} key={index}>

                              <Card className="card-label">
                                <div className="c-d-1"></div>
                                <a className="link" href={productdata.link}>
                                  <Card.Img
                                    variant="top"
                                    src={productdata.image}
                                  />
                                  <Card.Body>
                                    <Card.Title className="poppins-semibold text-black fs-24">
                                      {productdata.title}
                                    </Card.Title>
                                    <Card.Text className="text-black poppins-light fs-14">
                                      <b className="fw-topics poppins-semibold">Topics Covered:</b> {productdata.topics}
                                    </Card.Text>
                                    <Card.Text className="text-black poppins-light fs-14">
                                      {productdata.description}
                                    </Card.Text>
                                    <Card.Text>
                                      <FontAwesomeIcon
                                        icon="fa-solid fa-star"
                                        beat
                                        className="text-warning"
                                      />
                                      <span className="fw-bold poppins-light text-black">
                                        {productdata.rating + " "}
                                      </span>
                                      <span className="text-black poppins-light">

                                        ({productdata.short_desc} Reviews)
                                      </span>
                                    </Card.Text>
                                  </Card.Body>
                                </a>
                              </Card>
                            </Col>
                          </>
                        );
                      }
                    })}


                  </Row>
                </Tab.Pane>
                <Tab.Pane eventKey="second">
                  <Row className="gy-3 gx-md-5 mb-5 card-label1">
                    {props.product.map((productdata, index) => {
                      if (productdata.newcategory === 2) {


                        return (
                          <>
                            <Col xs={12} md={4} key={index}>

                              <Card className="card-label">
                                <div className="c-d-1"></div>
                                <a className="link" href={productdata.link}>
                                  <Card.Img
                                    variant="top"
                                    src={productdata.image}
                                  />
                                  <Card.Body>
                                    <Card.Title className="poppins-semibold text-black fs-24">
                                      {productdata.title}
                                    </Card.Title>
                                    <Card.Text className="text-black poppins-light fs-14">
                                      <b className="fw-topics poppins-semibold">Topics Covered:</b> {productdata.topics}
                                    </Card.Text>
                                    <Card.Text className="text-black poppins-light fs-14">
                                      {productdata.description}
                                    </Card.Text>
                                    <Card.Text>
                                      <FontAwesomeIcon
                                        icon="fa-solid fa-star"
                                        beat
                                        className="text-warning"
                                      />
                                      <span className="fw-bold poppins-light text-black">
                                        {productdata.rating + " "}
                                      </span>
                                      <span className="text-black poppins-light">

                                        ({productdata.short_desc} Reviews)
                                      </span>
                                    </Card.Text>
                                  </Card.Body>
                                </a>
                              </Card>
                            </Col>
                          </>
                        );
                      }
                    })}


                  </Row>
                </Tab.Pane>
                <Tab.Pane eventKey="thirt">
                  <Row className="gy-3 gx-md-5 mb-5 card-label1">
                    {props.product.map((productdata, index) => {
                      if (productdata.newcategory === 3) {


                        return (
                          <>
                            <Col xs={12} md={4} key={index}>

                              <Card className="card-label">
                                <div className="c-d-1"></div>
                                <a className="link" href={productdata.link}>
                                  <Card.Img
                                    variant="top"
                                    src={productdata.image}
                                  />
                                  <Card.Body>
                                    <Card.Title className="poppins-semibold text-black fs-24">
                                      {productdata.title}
                                    </Card.Title>
                                    <Card.Text className="text-black poppins-light fs-14">
                                      <b className="fw-topics poppins-semibold">Topics Covered:</b> {productdata.topics}
                                    </Card.Text>
                                    <Card.Text className="text-black poppins-light fs-14">
                                      {productdata.description}
                                    </Card.Text>
                                    <Card.Text>
                                      <FontAwesomeIcon
                                        icon="fa-solid fa-star"
                                        beat
                                        className="text-warning"
                                      />
                                      <span className="fw-bold poppins-light text-black">
                                        {productdata.rating + " "}
                                      </span>
                                      <span className="text-black poppins-light">

                                        ({productdata.short_desc} Reviews)
                                      </span>
                                    </Card.Text>
                                  </Card.Body>
                                </a>
                              </Card>
                            </Col>
                          </>
                        );
                      }
                    })}


                  {/* </Row>
                </Tab.Pane>
                <Tab.Pane eventKey="forth">
                  <Row className="gy-3 gx-md-5 mb-5 card-label1">
                    <Col sm={12}>
                      <p className="text-center poppins-semibold h2 border radius-15 py-5">Stay Tuned! Elective Courses Coming Soon</p>
                    </Col>


                  </Row>
                </Tab.Pane>
                <Tab.Pane eventKey="fifth">
                  <Row className="gy-3 gx-md-5 mb-5 card-label1">
                    <Col sm={12}>
                      <p className="text-center poppins-semibold h2 border radius-15 py-5">Stay Tuned! Micro Degrees Coming Soon</p>
                  </Col> */}

                  </Row>
                </Tab.Pane>
              </Tab.Content>
            </Col>
          </Row>
        </Tab.Container>
        {/* end new */}
        {/* <Row className="gy-3 gx-md-5 mb-5 card-label1">
          {props.product.map((productdata, index) => {
            return (
              <>
                <Col xs={12} md={4} key={index}>
                
                  <Card className="card-label">
                  <div className="c-d-1"></div>
                    <a className="link" to={"/productdetail/"+productdata.id}>
                      <Card.Img
                        variant="top"
                        src={baseurl + productdata.image}
                      />
                      <Card.Body>
                        <Card.Title className="poppins-semibold text-black">
                          {productdata.title}
                        </Card.Title>
                        <Card.Text className="text-black poppins-light">
                          {productdata.description}
                        </Card.Text>
                        <Card.Text>
                          <FontAwesomeIcon
                            icon="fa-solid fa-star"
                            beat
                            className="text-warning"
                          />
                          <span className="fw-bold poppins-light text-black">
                            &nbsp; 4.5&nbsp;
                          </span>
                          <span className="text-black poppins-light">
                           
                            ({productdata.short_desc} Reviews)
                          </span>
                        </Card.Text>
                      </Card.Body>
                    </a>
                  </Card>
                </Col>
              </>
            );
          })}

    
        </Row> */}





        {/* {props.featureproduct.map((featurproduct,index)=>{
 
return <Row className="gx-md-5  two-card-column1 mb-5" key={index}>
          <Col xs={12} md={7} className="column-order-1">
            <img src={baseurl+featurproduct.image} alt="" className="w-100 radius-15" />
          </Col>
          <Col xs={12} md={5} className="column-order-text ">
            <h5 className={"primary-text h1 poppins"}>{featurproduct.title}</h5>
            <p className={" primary-text l-1 poppins-light"}>
            {featurproduct.description}
            </p>
            <a className={" primary-text btn rounded-12 border-p"} to={featurproduct.link}>
                      
                      <i className="fa-solid fa-arrow-down  fa-2x rotate-225"></i>
                    </Link>
          </Col>
        </Row>
       })} */}


      </Container>
    </>
  );
};

export default ProductPage;
