import React from "react";
import Container from "react-bootstrap/Container";
import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import internship from "./../images/internship.png";
import internshipbg from "./../images/internship/internship-cards.jpg";
import internshiplogo1 from "./../images/internship/Group 168.png";
import internshiplogo2 from "./../images/internship/Group 169.png";
import internshiplogo3 from "./../images/internship/Group 170.png";
import internshiplogo4 from "./../images/internship/Group 171.png";
import internshiplogo5 from "./../images/internship/Group 172.png";
import internshiplogo6 from "./../images/internship/Group 174.png";
import internshiplogo7 from "./../images/internship/Group 175.png";
const Internship = () => {
  return (
    <>
      <section className="my-5">
        <Container>
          <Row className="d-none d-md-flex">
            <Col xs={12} md={7}>
              <picture>
                <source media="(max-width:576px)" srcset={internshipbg} />
                {/* <source media="(min-width:577px)" srcset={alllogo}/> */}
                <img src={internshipbg} alt="patners" className="w-100" />
              </picture>
            </Col>
            <Col xs={12} md={5} className="partner-text">
              <h4 className="display-2 text-color poppins-semibold">
                Internships & Opportunities
              </h4>
            </Col>
          </Row>

          <Container>
            <Row className="d-sm-flex d-md-none">
              <Col xs={12} className="partner-text">
                <h4 className="display-2 text-color poppins-semibold">
                  Internships & Opportunities
                </h4>
              </Col>
              <Col xs={12}>
                <Row className="mobile-intern">
                  <Col xs={4} className="my-secondary-bg">
                    <img src={internshiplogo2} alt="" />
                  </Col>
                  <Col xs={4} className="card-bg-img">
                    <img src={internshiplogo1} alt="" />
                  </Col>
                  <Col xs={4} className="my-secondary-bg">
                    <img src={internshiplogo3} alt="" />
                  </Col>
                  <Col xs={4} className="card-bg-img">
                    <img src={internshiplogo5} alt="" />
                  </Col>
                  <Col xs={4} className="my-secondary-bg">
                    <img src={internshiplogo4} alt="" />
                  </Col>
                  <Col xs={4} className="card-bg-img">
                    <img src={internshiplogo6} alt="" />
                  </Col>
                  <Col xs={4} className="my-secondary-bg">
                    <img src={internshiplogo7} alt="" />
                  </Col>
                </Row>
              </Col>
            </Row>
          </Container>
          <p className="my-5 h4 p-text poppins-light">
            Your story starts when you grab the opportunity and use it optimally. Providing you with the best internships and training workshops is one of the highlights of what we do at our company. Let us delegate the products as a stepping stone in your career.
          </p>
        </Container>
      </section>
    </>
  );
};

export default Internship;
