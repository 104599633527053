import React from 'react';
import Container from "react-bootstrap/Container";
import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import alllogo from "./../images/alllogo.png";
import alllogomobile from "./../images/alllogomobile.png";
const CollegePartner = () => {
  return (
    <>
      <section className='my-5'>
        <Container>
          <Row>
            <Col xs={12} md={5} className="partner-text">

              <h4 className='display-2 text-color poppins-semibold'>
                Industry & College Partners
              </h4>

            </Col>
            <Col xs={12} md={7}>
              <picture>
                <source media="(max-width:576px)" srcset={alllogomobile} />
                <source media="(min-width:577px)" srcset={alllogo} />
                <img src={alllogo} alt="patners" className='w-100' />
              </picture>
            </Col>
          </Row>
          <p className='my-5 h4 p-text poppins-light'>Join the esteemed network of college partners who have collaborated with us to shape the future of education and create opportunities for students. We are committed to providing rewarding internships and comprehensive training workshops. Let’s create a robust learning experience for every student.
          </p>
        </Container>
      </section>
    </>
  )
}

export default CollegePartner;