import React, { useEffect, useState } from 'react';

import './App.css';
import { Routes, Route } from "react-router-dom";
import Header from './includes/Header';
import { OurCourses } from './components/OurCourses';
import EventTabs from './components/EventTabs';
import ThreeCard from './components/ThreeCard';
import CollegePartner from './components/CollegePartner';
import Internship from './components/Internship';
import ProductPage from './components/ProductPage';
import TopBanner from './components/TopBanner';
import SingleProduct from './components/SingleProduct';
// import { baseurl } from './components/Config';
import Footer from './includes/Footer';
import Whyus from './components/Whyus';
import Review from './components/Review';
import Corporate from './components/Corporate';
import Coomingsoon from './components/Coomingsoon';
import logo from './images/ME logo white 1.png';
import Blog from './components/Blog';
import { Data } from './Data/Data.js';


function App() {
  const [product, setproduct] = useState([]);
  const [futureevent, setfutureevent] = useState([]);
  const [pastevent, setpastevent] = useState([]);
  const [blog, setblog] = useState([]);
  const [featureproduct, setfeatureproduct] = useState([]);
  const [allproduct, setallproduct] = useState([]);
  const [featureevent, setfeatureevent] = useState([]);


  useEffect(() => {


    const getdata = async () => {
      // const response = await fetch(`${baseurl}api/blog`)
      // const data = await response.json()
      // console.log(Data)




      // setpastevent(data.pastevent);
      // setfutureevent(data.futureevent);
      // setproduct(data.product);
      // setblog(data.post);
      // setfeatureproduct(data.feature_product);
      // setallproduct([...data.feature_product, ...data.products])
      // setfeatureevent(data.feature_event);

      setproduct(Data.product);
      setpastevent(Data.pastevents);

    }
    getdata();

    // setTimeout(() => {
    //     console.log(pastevent)
    // }, 4000);
  }, []);
  return (<>
    {/* <SingleProduct/> */}

    {/* <ProductPage/> */}


    <Routes>
      <Route path="/products" exact element={<ProductPage product={product} featureproduct={featureproduct} />} />
      <Route path="/corporate" exact element={<Corporate />} />
      {/* dummy route  */}

      <Route path="/whyus" exact element={<Whyus />} />

      <Route path="/coomingsoon" exact element={<Coomingsoon />} />
      {/* dummy route end */}
      <Route path="/" exact element={<>
        <Header color="white-768" logo={logo} />
        <TopBanner />
        <ThreeCard />
        <CollegePartner />
        <EventTabs futureevent={futureevent} pastevent={pastevent} blog={blog} product={product} featureproduct={featureproduct} featureevent={featureevent} />
        <OurCourses />
        <Internship />
        <Blog />
        <Review />
      </>} />
      {/* <Route path="/" element={<Layout />}>
      <Route index element={<Home />} />
    
    </Route> */}
      <Route exact path="/productdetail/:id" element={<SingleProduct allproduct={allproduct} product={product} featureproduct={featureproduct} />} />

    </Routes>
    <Footer />
  </>
  );
}

export default App;
