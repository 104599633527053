import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Link } from "react-router-dom";
import Card from "react-bootstrap/Card";
import * as React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Container from "react-bootstrap/Container";
import PastEvents from './PastEvents';
import TwoColumnProduct from './TwoColumnProduct';
import BlogCard from './BlogCard';
import Review from './Review';
import Slider from "react-slick";
import Button from "react-bootstrap/Button";
interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function EventTabs(props) {
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  var settings = {
    dots: true,
    infinite: true,
    speed: 500,

    slidesToShow: 2,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
        },
      },
    ],
  };
  return (
    <>
      <section className='mt-5 pt-3 bg-banner'>
        <Container>
          <Box sx={{ width: '100%' }}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <Tabs value={value} onChange={handleChange} aria-label="basic tabs example"
                className='' variant="scrollable" indicatorColor="primary">
                <Tab label="Upcoming Workshops" {...a11yProps(0)} className="text-white pd-event fs-5 text-capitalize" />
                {/* <Tab label="Past Events" {...a11yProps(0)} className="text-white pd-event fs-5 text-capitalize" />
                <Tab label="Future Events" {...a11yProps(1)} className="text-white pd-event fs-5 text-capitalize" />
                <Tab label="Projects" {...a11yProps(2)} className="text-white pd-event fs-5 text-capitalize" />
                <Tab label="Blogs" {...a11yProps(3)} className="text-white pd-event fs-5 text-capitalize" /> */}
                {/* <Tab label="Reviews" {...a11yProps(4)} className="text-white pd-event fs-5 text-capitalize"/> */}
              </Tabs>
            </Box>

            <TabPanel value={value} index={0}>
              <Container>

                <Row className="gy-3 mb-5">
                  {/* start rough  */}
                  <Slider {...settings}>
                    {props.product.map((productdata, index) => {
                      if (productdata.newcategory === 0) {
                        return (<>
                          <Col xs={12} key={index} className="d-flex align-items-center justify-content-center">
                            <Card className="relative border-1 radius-15 eventtabcard">
                              <div className="bg-g radius-15"></div>
                              <Card.Img variant="top" className="radius-15" src={productdata.image} />
                              <Card.Body className="">
                                <Card.Text className="black-card-text poppins-light">
                                  <div className="fw-bold h5 card-title poppins">
                                    {productdata.title} {
                                      productdata.extra &&
                                      <b className="free poppins">{`(${productdata.extra})`}</b>
                                    }
                                  </div>
                                  {productdata.description}
                                </Card.Text>
                                {
                                  productdata.title === "Robo AI" ?
                                    <div className="d-flex gap-4 align-items-center justify-content-center">
                                      <a className="btn btn-hover btn-outline-secondary btn-color top-btn-p evt-btn" href="https://www.roboai.in/static/media/roboai.d5cdfa607f2fee9f1146.pdf" target="_blank">Brochure</a>
                                      <a href="https://pages.razorpay.com/website2" target="_blank"><Button variant="btn btn-hover btn-warning lead top-btn-p">Pay Now</Button></a>
                                      <a href="https://roboai.myequation.in" target="_blank"><Button variant="btn btn-hover btn-warning lead top-btn-p">CHECK OUT </Button></a>
                                    </div> :
                                    <div className="d-flex align-items-center flex-column justify-content-center">
                                      <p className="fs-6 poppins free-text">Reserve a seat before 21st june'24</p>
                                      <p className="fs-6 poppins free-text"></p>
                                      <p className="fs-6 poppins free-text"></p>
                                      <p className="fs-6 poppins free-text"></p>


                                      <a href="https://robopack.myequation.in" target="_blank"><Button variant="btn btn-hover btn-warning lead top-btn-p">CHECK OUT </Button></a>
                                      <a href={productdata.link} target="_blank"></a>
                                      </div>  
                                    
                                }
                              </Card.Body>
                            </Card>
                          </Col >
                        </>)
                      }
                    })}
                  </Slider>
                </Row>
              </Container>
            </TabPanel>
            <Container className="d-flex align-items-center justify-content-center pb-4 gap-2">
              <p className="fs-5 text-white m-0 text-capitalize poppins">More Products</p>
              <Link className={"btn rounded-12 border-w"} to={"/products?cat=1"}>
                <i className="fa-solid fa-arrow-down text-white  fa-2x rotate-225"></i>
              </Link>
            </Container>
            {/* <TabPanel value={value} index={0}>
              <PastEvents pastevent={props.pastevent} featureevent={props.featureevent} />
            </TabPanel> */}
            {/* <TabPanel value={value} index={1}>
              {props.futureevent.map((fututedata, index) => {
                return <TwoColumnProduct textColor="text-white" key={index} title={fututedata.title} description={fututedata.description} image={fututedata.image} link={fututedata.link} />
              })}

            </TabPanel> */}
            {/* <TabPanel value={value} index={2}>
              {props.featureproduct.map((featurproduct, index) => {
                return <TwoColumnProduct textColor="text-white" key={index} title={featurproduct.title} description={featurproduct.description} image={featurproduct.image} link={featurproduct.link} />
              })}
            </TabPanel>
            <TabPanel value={value} index={3}>
              <BlogCard blog={props.blog} />
            </TabPanel> */}
            {/* <TabPanel value={value} index={4}>
       <Review/>
      </TabPanel> */}
          </Box>
        </Container >
      </section >
    </>
  );
}

// import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
// import 'react-tabs/style/react-tabs.css';

// export default function EventTabs() {
//   return(<>
//   <Tabs>
//     <TabList>
//       <Tab>Title 1</Tab>
//       <Tab>Title 2</Tab>
//     </TabList>

//     <TabPanel>
//       <h2>Any content 1</h2>
//     </TabPanel>
//     <TabPanel>
//       <h2>Any content 2</h2>
//     </TabPanel>
//   </Tabs>
//     </>)}
// ;

// import * as React from 'react';
// import Tabs from '@mui/material/Tabs';
// import Tab from '@mui/material/Tab';
// import Typography from '@mui/material/Typography';
// import Box from '@mui/material/Box';
// import PropTypes from 'prop-types';
// import { AppBar, makeStyles } from '@mui/material';


// function TabPanel(props) {
//   const { children, value, index, ...other } = props;

//   return (
//     <div
//       role="tabpanel"
//       hidden={value !== index}
//       id={`scrollable-auto-tabpanel-${index}`}
//       aria-labelledby={`scrollable-auto-tab-${index}`}
//       {...other}
//     >
//       {value === index && (
//         <Box p={3}>
//           <Typography>{children}</Typography>
//         </Box>
//       )}
//     </div>
//   );
// }

// TabPanel.propTypes = {
//   children: PropTypes.node,
//   index: PropTypes.any.isRequired,
//   value: PropTypes.any.isRequired,
// };

// function a11yProps(index) {
//   return {
//     id: `scrollable-auto-tab-${index}`,
//     'aria-controls': `scrollable-auto-tabpanel-${index}`,
//   };
// }

// const useStyles = makeStyles((theme) => ({
//   root: {
//     flexGrow: 1,
//     width: '100%',
//     backgroundColor: theme.palette.background.paper,
//   },
// }));

// export default function EventTabs() {
//   const classes = useStyles();
//   const [value, setValue] = React.useState(0);

//   const handleChange = (event, newValue) => {
//     setValue(newValue);
//   };

//   return (
//     <div className={classes.root}>
//       <AppBar position="static" color="default">
//         <Tabs
//           value={value}
//           onChange={handleChange}
//           indicatorColor="primary"
//           textColor="primary"
//           variant="scrollable"
//           scrollButtons="auto"
//           aria-label="scrollable auto tabs example"
//         >
//           <Tab label="Item One" {...a11yProps(0)} />
//           <Tab label="Item Two" {...a11yProps(1)} />
//           <Tab label="Item Three" {...a11yProps(2)} />
//           <Tab label="Item Four" {...a11yProps(3)} />
//           <Tab label="Item Five" {...a11yProps(4)} />
//           <Tab label="Item Six" {...a11yProps(5)} />
//           <Tab label="Item Seven" {...a11yProps(6)} />
//         </Tabs>
//       </AppBar>
//       <TabPanel value={value} index={0}>
//         Item One
//       </TabPanel>
//       <TabPanel value={value} index={1}>
//         Item Two
//       </TabPanel>
//       <TabPanel value={value} index={2}>
//         Item Three
//       </TabPanel>
//       <TabPanel value={value} index={3}>
//         Item Four
//       </TabPanel>
//       <TabPanel value={value} index={4}>
//         Item Five
//       </TabPanel>
//       <TabPanel value={value} index={5}>
//         Item Six
//       </TabPanel>
//       <TabPanel value={value} index={6}>
//         Item Seven
//       </TabPanel>
//     </div>
//   );
// }

// import React from 'react'
// import { Tab } from '@mui/material';
// const EventTabs = () => {
//   return (

//   )
// }

// export default EventTabs



{/* <Box sx={{ bgcolor: 'background.paper' }}>
      <Tabs
        value={value}
        onChange={handleChange}
        variant="scrollable"
        scrollButtons="auto"
        aria-label="scrollable auto tabs example" */}



