import React, { useState } from "react";
import Container from "react-bootstrap/Container";
import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Link } from "react-router-dom";

import team from "./../images/icon/team.png";
import ss1 from "./../images/product/ss1.png";
import ss2 from "./../images/product/ss2.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useParams } from "react-router";
import { baseurl } from "./Config";
import Header from './../includes/Header';
import logo from './../images/ME logo white 1.png';
const SingleProduct = (props) => {

  const { id } = useParams();

  return (
    <>
      <Header color="white-768" logo={logo} />
      {props.allproduct.map((dataallproduct, index) => {
        if (dataallproduct.id == id) {
          const tags = dataallproduct.tags.split(",");
          const info = dataallproduct.info.replace(/\\/g, "");
          const info2 = JSON.parse(info);

          return (
            <>
              <div className="single-p-bg">
                <Container className="banner-top-padding pb-80">
                  <Row>
                    <Col xs={12} md={6} className="">
                      <h5 className="text-white h1 three-rem poppins-semibold">
                        {dataallproduct.title}
                      </h5>
                      <p className="text-white l-1 font-17 poppins">
                        {dataallproduct.description}
                      </p>
                      <p>
                        <FontAwesomeIcon
                          icon="fa-solid fa-star"
                          beat
                          className="text-warning pe-1"
                        />
                        <FontAwesomeIcon
                          icon="fa-solid fa-star"
                          beat
                          className="text-warning pe-1"
                        />
                        <FontAwesomeIcon
                          icon="fa-solid fa-star"
                          beat
                          className="text-warning pe-1"
                        />
                        <FontAwesomeIcon
                          icon="fa-solid fa-star"
                          beat
                          className="text-warning pe-1"
                        />

                        <span className="fw-bold text-white">
                          &nbsp; 4.5&nbsp;
                        </span>
                        <span className="text-white">
                          ({dataallproduct.short_desc} Reviews)
                        </span>
                      </p>
                      <div className="d-flex pb-3">
                        {tags.map((tagsdata, index1) => {
                          return (
                            <p className="m-0 p-0 mx-2 px-4 py-1 text-white bg-secondary border-50">
                              {tagsdata}
                            </p>
                          );
                        })}
                      </div>
                    </Col>
                    <Col xs={12} md={5} className="offset-md-1">
                      <img
                        src={baseurl + dataallproduct.image_more_1}
                        alt=""
                        className="w-100 radius-15 border"
                      />
                      <div className="relative">
                        <Card className="add-to-cart py-2">
                          <Card.Body>
                            <div className="d-flex justify-content-between align-items-center">
                              <p className="h2 poppins-semibold card-title">
                                $ {dataallproduct.saleprice}
                                <span className="fw-light h5 text-decoration-line-through text-secondary">
                                  &nbsp;$ {dataallproduct.regularprice}
                                </span>
                              </p>
                              <p className="h6 poppins-medium">Discount 43%</p>
                            </div>
                            <div className="d-flex justify-content-between align-items-center">
                              <Link className="btn btn-warning poppins-semibold fs-5 me-2 py-md-2 w-100">
                                Add To Cart
                              </Link>
                              <Link className="btn btn-dark bg-white text-black poppins-semibold fs-5 me-2 py-md-2 w-100">
                                Buy Now
                              </Link>
                            </div>
                          </Card.Body>
                        </Card>
                      </div>
                    </Col>
                    <Col sm={12} md={6}></Col>
                  </Row>
                </Container>
              </div>

              <Container className="my-5 mt-20">
                <Row className="mt-5">
                  <Col sm={12} md={6}>
                    <Card>
                      <Card.Body>
                        <h4 className="fw-bold">
                          This course will enable you to
                        </h4>
                        <Row>
                          <Col sm={6}>
                            <ul>
                              <li>Lorem ipsum dolor sit amet</li>
                            </ul>
                          </Col>
                          <Col sm={6}>
                            <ul>
                              <li>Lorem ipsum dolor sit amet</li>
                            </ul>
                          </Col>
                        </Row>
                      </Card.Body>
                    </Card>
                    {/* start chapter */}
                    <Card className="mt-4">
                      <Card.Body>
                        <h4 className="fw-bold">Chapters</h4>
                        <p className="text-secondary">{info2.length} Chapters | 14h 24m</p>

                        {/* start tabs */}

                        <div className="tabs">
                          {info2.map((chapter, ind) => {
                            return (
                              <>
                                <input
                                  type="radio"
                                  name="tabs"
                                  id={ind + "tab"}
                                  checked="checked"
                                />
                                <label for={ind + "tab"} className="d-flex justify-content-between">
                                  <div><span className="a"><i className="fa-solid fa-chevron-down"></i></span>
                                    <span className="m"><i className="fa-solid fa-chevron-up"></i></span>
                                    &nbsp; {chapter.title} </div>
                                  <span>1h 4m</span>
                                </label>
                                <div className="tab">
                                  <p>{chapter.text}</p>
                                </div>
                              </>
                            );
                          })}
                        </div>

                        {/* end tabs */}
                      </Card.Body>
                    </Card>
                  </Col>
                  <Col xs={12} md={5} className="offset-md-1">
                    <img src={ss1} alt="" className="mt-5 w-100" />
                    <img src={ss2} alt="" className="w-auto" />
                  </Col>
                </Row>
                <hr className="mt-5" />
                <h2 className="poppins-semibold h2 my-5">Meet the Mentor</h2>
                <Row className="align-items-center">
                  <Col sm={12} md={6}>
                    <div className="d-flex align-items-center">
                      <div className="d-none d-md-block">
                        <img
                          className="mantor-img"
                          src={baseurl + dataallproduct.image_more_2}
                          alt=""
                        />
                      </div>
                      <div>
                        <h4 className="poppins-medium">
                          <img
                            className="height-50 d-md-none"
                            src={baseurl + dataallproduct.image_more_2}
                            alt=""
                          />john Jacob</h4>
                        <h5 className="poppins-medium">Lorem ipsum dolor sit amet</h5>
                        <p className="poppins-light">
                          lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem
                          ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum
                          lorem ipsum lorem ipsum lorem ipsum lorem ipsu
                        </p>
                      </div>
                    </div>
                  </Col>
                  <Col sm={12} md={5} className="offset-md-1">
                    <p>

                      <FontAwesomeIcon
                        icon="fa-solid fa-star"
                        beat
                        className="pe-1"
                      />
                      4.5 Instructor Ratings
                    </p>
                    <p> <img src={team} style={{ width: "1rem" }} alt="" /> 1,122 Students</p>
                    <p> <i className="fa-solid fa-award pe-1"></i>{dataallproduct.short_desc} Reviews</p>
                  </Col>
                </Row>
              </Container>
            </>
          );
        }
      })}
    </>
  );
};

export default SingleProduct;
